/* Framework imports ----------------------------------- */
import React from 'react';
import ReactDOM from 'react-dom';
import { Capacitor } from '@capacitor/core';

/* Component imports ----------------------------------- */
import { ApolloProvider } from '@apollo/client';
import * as Redux from 'react-redux';

/* Module imports -------------------------------------- */
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  store as reduxStore,
  persistor as reduxPersistor,
} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import {
  setGeolocationReady,
  setGeolocationEnabled,
  setGeolocationLocation,
  setGeolocationActivity,
  setGeolocationIsMoving,
} from './redux/slices/geolocationSlice';
import { initApolloClient } from './helpers/initApolloClient';
import BackgroundGeolocationManager from './helpers/BackgroundGeolocationManager';

/* Import the App component ---------------------------- */
import App from './App/App';

/* Configuration imports ------------------------------- */
import ERP_SERVER_CONFIG from './config/erpServer.config.json';

/* Type imports ---------------------------------------- */

/* Initialize application ------------------------------ */
console.log(`[INFO ] <index> Capacitor platform is :`, Capacitor.getPlatform());
console.log(`[INFO ] <index> BackgroundGeolocation plugin availability :`, Capacitor.isPluginAvailable('BackgroundGeolocation'));
console.log(`[INFO ] <index> CodePush plugin availability :`, Capacitor.isPluginAvailable('CodePush'));

const apolloClient = initApolloClient(
  {
    backendURL: ERP_SERVER_CONFIG.ODOO_HOST,
    graphQLEndpoint: ERP_SERVER_CONFIG.GRAPHQL_ENDPOINT,
  },
);

if(
  (
    Capacitor.getPlatform() === 'android' ||
    Capacitor.getPlatform() === 'ios'
  ) &&
  Capacitor.isPluginAvailable('BackgroundGeolocation')
) {
  BackgroundGeolocationManager.initBackgroundGeolocation(
    (pReady) => reduxStore.dispatch(setGeolocationReady(pReady)),
    (pEnabled) => reduxStore.dispatch(setGeolocationEnabled(pEnabled)),
    (pLocation) => reduxStore.dispatch(setGeolocationLocation(pLocation)),
    (pActivity) => reduxStore.dispatch(setGeolocationActivity(pActivity)),
    (pIsMoving) => reduxStore.dispatch(setGeolocationIsMoving(pIsMoving)),
  )
    .then(
      (pBGGeolocState) => {
        console.log(`[INFO ] <index> Initialized the background geolocation process :`, pBGGeolocState);
      },
    )
    .catch(
      (pException) => {
        console.error(`[ERROR] <index> Failed to initialize the background geolocation process :`, pException);
      },
    );
}

/* Render the App -------------------------------------- */
ReactDOM.render(
  <React.StrictMode>
    <Redux.Provider store={reduxStore}>
      <PersistGate
        loading={null}
        persistor={reduxPersistor}
      >
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </PersistGate>
    </Redux.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
