/* Framework imports ----------------------------------- */
import * as Redux from 'react-redux';

/* Module imports -------------------------------------- */
import { selectAuthInfo } from './slices/authSlice';
import { selectGeolocationData } from './slices/geolocationSlice';

/* Type imports ---------------------------------------- */
import type {
  RootState,
  AppDispatch,
} from './store';
import type { AuthData } from '../types/AuthData';
import type { GeolocationResult } from '../types/GeolocationResult';

/* Generic Redux store hooks --------------------------- */
export const useAppDispatch: () => AppDispatch = Redux.useDispatch;
export const useAppSelector: Redux.TypedUseSelectorHook<RootState> = Redux.useSelector;

/* Specific Redux store hooks -------------------------- */
export const useAuthInfo = (): AuthData | null => {
  const lLoginInfo: AuthData = useAppSelector(
    selectAuthInfo,
    (pLeft: AuthData, pRight: AuthData): boolean => {
      return pLeft.authUID === pRight.authUID &&
        pLeft.authToken === pRight.authToken &&
        pLeft.sessionID === pRight.sessionID &&
        pLeft.isLoggedIn === pRight.isLoggedIn;
    },
  );

  return lLoginInfo;
};

export const useGeolocationData = (): GeolocationResult => {
  return useAppSelector(selectGeolocationData);
};
