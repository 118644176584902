import * as Types from "./types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
const defaultOptions = {} as const;
export const PartnerFieldsFragmentDoc = gql`
  fragment PartnerFields on ResPartner {
    id
    name
    currency {
      id
      name
    }
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on ResUsers {
    id
    name
    role {
      key
    }
    parentId {
      ...PartnerFields
    }
    divisionId {
      ...PartnerFields
    }
  }
  ${PartnerFieldsFragmentDoc}
`;
export const PassengerFragmentDoc = gql`
  fragment Passenger on ResUsers {
    id
    displayName
    name
    firstname
    email
    phone
    isPassengersGroup
    groupName
    groupSize
  }
`;
export const MissionRideFragmentDoc = gql`
  fragment MissionRide on CommunicorRide {
    createDate
    displayName
    endDate
    endPlace
    id
    startDate
    startPlace
  }
`;
export const VehicleTypeFragmentDoc = gql`
  fragment VehicleType on CommunicorVehicleType {
    createDate
    description
    displayName
    id
    name
    numberSeats
  }
`;
export const MissionFragmentDoc = gql`
  fragment Mission on CommunicorMission {
    createDate
    displayName
    driverId {
      ...UserFields
    }
    id
    passengerId {
      ...Passenger
    }
    rideIds {
      ...MissionRide
    }
    vehicleTypeId {
      ...VehicleType
    }
  }
  ${UserFieldsFragmentDoc}
  ${PassengerFragmentDoc}
  ${MissionRideFragmentDoc}
  ${VehicleTypeFragmentDoc}
`;
export const RideMissionFragmentDoc = gql`
  fragment RideMission on CommunicorMission {
    displayName
    id
  }
`;
export const RideFragmentDoc = gql`
  fragment Ride on CommunicorRide {
    confirmUrl
    createDate
    description
    displayName
    driverId {
      ...UserFields
    }
    endDate
    endPlace
    driverEndDate
    driverEndKilometer
    driverEndPlace
    id
    missionId {
      ...RideMission
    }
    passengerId {
      ...Passenger
    }
    startDate
    startPlace
    driverStartDate
    driverStartKilometer
    driverStartPlace
    state {
      key
      value
    }
    type {
      key
      value
    }
  }
  ${UserFieldsFragmentDoc}
  ${RideMissionFragmentDoc}
  ${PassengerFragmentDoc}
`;
export const CurrentUserDocument = gql`
  query CurrentUser {
    getMyData {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type CurrentUserProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >;
} & TChildProps;
export function withCurrentUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables,
    CurrentUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables,
    CurrentUserProps<TChildProps, TDataName>
  >(CurrentUserDocument, {
    alias: "currentUser",
    ...operationOptions,
  });
}

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >(CurrentUserDocument, options);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  Types.CurrentUserQuery,
  Types.CurrentUserQueryVariables
>;
export const RideDocument = gql`
  query Ride($id: Int!) {
    getCommunicorRide(id: $id) {
      ...Ride
    }
  }
  ${RideFragmentDoc}
`;
export type RideProps<TChildProps = {}, TDataName extends string = "data"> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.RideQuery,
    Types.RideQueryVariables
  >;
} & TChildProps;
export function withRide<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.RideQuery,
    Types.RideQueryVariables,
    RideProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.RideQuery,
    Types.RideQueryVariables,
    RideProps<TChildProps, TDataName>
  >(RideDocument, {
    alias: "ride",
    ...operationOptions,
  });
}

/**
 * __useRideQuery__
 *
 * To run a query within a React component, call `useRideQuery` and pass it any options that fit your needs.
 * When your component renders, `useRideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRideQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRideQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.RideQuery,
    Types.RideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.RideQuery, Types.RideQueryVariables>(
    RideDocument,
    options
  );
}
export function useRideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RideQuery,
    Types.RideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.RideQuery, Types.RideQueryVariables>(
    RideDocument,
    options
  );
}
export type RideQueryHookResult = ReturnType<typeof useRideQuery>;
export type RideLazyQueryHookResult = ReturnType<typeof useRideLazyQuery>;
export type RideQueryResult = Apollo.QueryResult<
  Types.RideQuery,
  Types.RideQueryVariables
>;
export const RidesListDocument = gql`
  query RidesList(
    $currentFilter: String!
    $todayFilter: String!
    $plannedFilter: String!
    $doneTodayFilter: String!
    $limit: Int
    $offset: Int
    $order: String
  ) {
    currentRide: searchCommunicorRide(filter: $currentFilter) {
      ...Ride
    }
    todayRides: searchCommunicorRide(filter: $todayFilter, order: $order) {
      ...Ride
    }
    plannedRides: searchCommunicorRide(
      filter: $plannedFilter
      limit: $limit
      offset: $offset
      order: $order
    ) {
      ...Ride
    }
    doneTodayRides: searchCommunicorRide(filter: $doneTodayFilter) {
      ...Ride
    }
  }
  ${RideFragmentDoc}
`;
export type RidesListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.RidesListQuery,
    Types.RidesListQueryVariables
  >;
} & TChildProps;
export function withRidesList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.RidesListQuery,
    Types.RidesListQueryVariables,
    RidesListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.RidesListQuery,
    Types.RidesListQueryVariables,
    RidesListProps<TChildProps, TDataName>
  >(RidesListDocument, {
    alias: "ridesList",
    ...operationOptions,
  });
}

/**
 * __useRidesListQuery__
 *
 * To run a query within a React component, call `useRidesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRidesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRidesListQuery({
 *   variables: {
 *      currentFilter: // value for 'currentFilter'
 *      todayFilter: // value for 'todayFilter'
 *      plannedFilter: // value for 'plannedFilter'
 *      doneTodayFilter: // value for 'doneTodayFilter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRidesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.RidesListQuery,
    Types.RidesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.RidesListQuery, Types.RidesListQueryVariables>(
    RidesListDocument,
    options
  );
}
export function useRidesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RidesListQuery,
    Types.RidesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.RidesListQuery,
    Types.RidesListQueryVariables
  >(RidesListDocument, options);
}
export type RidesListQueryHookResult = ReturnType<typeof useRidesListQuery>;
export type RidesListLazyQueryHookResult = ReturnType<
  typeof useRidesListLazyQuery
>;
export type RidesListQueryResult = Apollo.QueryResult<
  Types.RidesListQuery,
  Types.RidesListQueryVariables
>;
