/* Framework imports ----------------------------------- */

/* Module imports -------------------------------------- */
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

/* Type imports ---------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit';
import type { PersistConfig } from 'redux-persist';
import type { AuthData } from '../../types/AuthData';
import type { RootState } from '../store';

/* Auth slice initial values ------------------------- */
const initialValues: AuthData = {
  isLoggedIn: false,
  authUID: null,
  authToken: null,
  sessionID: null,
};

/* Auth Redux slice ------------------------------------ */
const authSlice = createSlice(
  {
    name: 'auth',
    initialState: initialValues,
    reducers: {
      setAuthInfo: (
        state,
        { payload }: PayloadAction<AuthData>,
      ) => {
        state.authUID = payload.authUID;
        state.authToken = payload.authToken;
        state.isLoggedIn = payload.isLoggedIn;
        state.sessionID = payload.sessionID;
      },
      resetAuthInfo: (state) => {
        state.authUID = null;
        state.authToken = null;
        state.isLoggedIn = false;
        state.sessionID = null;
      },
    },
  },
);

/* Export slice components ----------------------------- */
export const {
  setAuthInfo,
  resetAuthInfo,
} = authSlice.actions;

export default authSlice.reducer;

export const selectAuthInfo = (state: RootState): AuthData => {
  return state.auth;
};

/* Persistance configuration --------------------------- */
const authPersistConfig: PersistConfig<AuthData> = {
  key: 'root',
  storage,
};

export const persistedAuthReducer = persistReducer(
  authPersistConfig,
  authSlice.reducer,
);
