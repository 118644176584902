/* Framework imports ----------------------------------- */
import React from 'react';

/* Module imports -------------------------------------- */

/* Component imports ----------------------------------- */
import QRCode from 'qrcode.react';

/* Style imports --------------------------------------- */

/* Type imports ---------------------------------------- */
import type { RideFragment } from '../../apollo/queries/types';

/* Styling --------------------------------------------- */
const qrCodeContainer: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const qrCodeStyle: React.CSSProperties = {
  borderRadius: '.5rem',
  margin: '1rem',
};

/* RideConfirmationQR component prop types ------------- */
interface RideConfirmationQRProps {
  ride: RideFragment;
}

/* RideConfirmationQR component ------------------------ */
const RideConfirmationQR: React.FC<RideConfirmationQRProps> = ({ ride }) => {
  if(
    ride.confirmUrl === null ||
    ride.confirmUrl === undefined
  ) {
    return null; /* TODO : Fallback ? */
  }

  return (
    <div style={qrCodeContainer}>
      <QRCode
        value={ride.confirmUrl}
        renderAs="svg"
        size={256}
        // level="H"
        fgColor="#FFFFFF"
        bgColor="#000000"
        style={qrCodeStyle}
      />
    </div>
  );
};

/* Export RideConfirmationQR component ----------------- */
export default RideConfirmationQR;
