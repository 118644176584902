import * as Types from "./types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
const defaultOptions = {} as const;
export const PartnerFieldsFragmentDoc = gql`
  fragment PartnerFields on ResPartner {
    id
    name
    currency {
      id
      name
    }
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on ResUsers {
    id
    name
    role {
      key
    }
    parentId {
      ...PartnerFields
    }
    divisionId {
      ...PartnerFields
    }
  }
  ${PartnerFieldsFragmentDoc}
`;
export const PassengerFragmentDoc = gql`
  fragment Passenger on ResUsers {
    id
    displayName
    name
    firstname
    email
    phone
    isPassengersGroup
    groupName
    groupSize
  }
`;
export const MissionRideFragmentDoc = gql`
  fragment MissionRide on CommunicorRide {
    createDate
    displayName
    endDate
    endPlace
    id
    startDate
    startPlace
  }
`;
export const VehicleTypeFragmentDoc = gql`
  fragment VehicleType on CommunicorVehicleType {
    createDate
    description
    displayName
    id
    name
    numberSeats
  }
`;
export const MissionFragmentDoc = gql`
  fragment Mission on CommunicorMission {
    createDate
    displayName
    driverId {
      ...UserFields
    }
    id
    passengerId {
      ...Passenger
    }
    rideIds {
      ...MissionRide
    }
    vehicleTypeId {
      ...VehicleType
    }
  }
  ${UserFieldsFragmentDoc}
  ${PassengerFragmentDoc}
  ${MissionRideFragmentDoc}
  ${VehicleTypeFragmentDoc}
`;
export const RideMissionFragmentDoc = gql`
  fragment RideMission on CommunicorMission {
    displayName
    id
  }
`;
export const RideFragmentDoc = gql`
  fragment Ride on CommunicorRide {
    confirmUrl
    createDate
    description
    displayName
    driverId {
      ...UserFields
    }
    endDate
    endPlace
    driverEndDate
    driverEndKilometer
    driverEndPlace
    id
    missionId {
      ...RideMission
    }
    passengerId {
      ...Passenger
    }
    startDate
    startPlace
    driverStartDate
    driverStartKilometer
    driverStartPlace
    state {
      key
      value
    }
    type {
      key
      value
    }
  }
  ${UserFieldsFragmentDoc}
  ${RideMissionFragmentDoc}
  ${PassengerFragmentDoc}
`;
export const CompleteRideDocument = gql`
  mutation CompleteRide(
    $id: Int!
    $currentDate: DateTime!
    $currentPlace: String!
    $currentKilometers: Float!
  ) {
    actionEndRide(
      id: $id
      endDate: $currentDate
      endPlace: $currentPlace
      kilometers: $currentKilometers
    ) {
      ok
    }
  }
`;
export type CompleteRideMutationFn = Apollo.MutationFunction<
  Types.CompleteRideMutation,
  Types.CompleteRideMutationVariables
>;
export type CompleteRideProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CompleteRideMutation,
    Types.CompleteRideMutationVariables
  >;
} & TChildProps;
export function withCompleteRide<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CompleteRideMutation,
    Types.CompleteRideMutationVariables,
    CompleteRideProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CompleteRideMutation,
    Types.CompleteRideMutationVariables,
    CompleteRideProps<TChildProps, TDataName>
  >(CompleteRideDocument, {
    alias: "completeRide",
    ...operationOptions,
  });
}

/**
 * __useCompleteRideMutation__
 *
 * To run a mutation, you first call `useCompleteRideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteRideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeRideMutation, { data, loading, error }] = useCompleteRideMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currentDate: // value for 'currentDate'
 *      currentPlace: // value for 'currentPlace'
 *      currentKilometers: // value for 'currentKilometers'
 *   },
 * });
 */
export function useCompleteRideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CompleteRideMutation,
    Types.CompleteRideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CompleteRideMutation,
    Types.CompleteRideMutationVariables
  >(CompleteRideDocument, options);
}
export type CompleteRideMutationHookResult = ReturnType<
  typeof useCompleteRideMutation
>;
export type CompleteRideMutationResult =
  Apollo.MutationResult<Types.CompleteRideMutation>;
export type CompleteRideMutationOptions = Apollo.BaseMutationOptions<
  Types.CompleteRideMutation,
  Types.CompleteRideMutationVariables
>;
export const StartRideDocument = gql`
  mutation StartRide(
    $id: Int!
    $currentDate: DateTime!
    $currentPlace: String!
    $currentKilometers: Float!
  ) {
    actionStartRide(
      id: $id
      startDate: $currentDate
      startPlace: $currentPlace
      kilometers: $currentKilometers
    ) {
      ok
    }
  }
`;
export type StartRideMutationFn = Apollo.MutationFunction<
  Types.StartRideMutation,
  Types.StartRideMutationVariables
>;
export type StartRideProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.StartRideMutation,
    Types.StartRideMutationVariables
  >;
} & TChildProps;
export function withStartRide<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.StartRideMutation,
    Types.StartRideMutationVariables,
    StartRideProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.StartRideMutation,
    Types.StartRideMutationVariables,
    StartRideProps<TChildProps, TDataName>
  >(StartRideDocument, {
    alias: "startRide",
    ...operationOptions,
  });
}

/**
 * __useStartRideMutation__
 *
 * To run a mutation, you first call `useStartRideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartRideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startRideMutation, { data, loading, error }] = useStartRideMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currentDate: // value for 'currentDate'
 *      currentPlace: // value for 'currentPlace'
 *      currentKilometers: // value for 'currentKilometers'
 *   },
 * });
 */
export function useStartRideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.StartRideMutation,
    Types.StartRideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.StartRideMutation,
    Types.StartRideMutationVariables
  >(StartRideDocument, options);
}
export type StartRideMutationHookResult = ReturnType<
  typeof useStartRideMutation
>;
export type StartRideMutationResult =
  Apollo.MutationResult<Types.StartRideMutation>;
export type StartRideMutationOptions = Apollo.BaseMutationOptions<
  Types.StartRideMutation,
  Types.StartRideMutationVariables
>;
