/* Framework imports ----------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react';

/* Module imports -------------------------------------- */
import {
  IonItem,
  IonLabel,
  IonToggle,
} from '@ionic/react';
import { toast } from 'react-toastify';
import { useAuthInfo } from '../../../redux/hooks';

/* Type imports ---------------------------------------- */
import type { ToggleChangeEventDetail } from '@ionic/core';
import type { State } from '@transistorsoft/capacitor-background-geolocation';
import type { AuthData } from '../../../types/AuthData';

/* GeolocControls component prop types ----------------- */
interface GeolocControlsProps {
  ready?: boolean;
  enabled?: boolean;
  startTracker: (pAuthData: AuthData) => Promise<State>;
  stopTracker: () => Promise<State | void>;
}

/* GeolocControls component ---------------------------- */
const GeolocControls: React.FC<GeolocControlsProps> = (
  {
    ready,
    enabled,
    startTracker,
    stopTracker,
  },
) => {
  const authData = useAuthInfo();

  const [ localEnabled, setLocalEnabled ] = useState<boolean>(false);

  const onStartGeoloc = (pEvent: CustomEvent<ToggleChangeEventDetail>): void => {
    console.log(`[DEBUG] <GeolocControls.onStartGeoloc> Starting the geolocation tracking process...`);
    setLocalEnabled(true);

    if(authData !== null) {
      startTracker(authData)
        .then(
          (pState) => {
            console.log('[INFO ] <GeolocControls.onStartGeoloc> Started tracking geolocation :', pState);
            toast.success(
              `Started tracking your geolocation`,
              {
                progress: undefined,
              },
            );
          },
        )
        .catch(
          (pException) => {
            console.error(`[ERROR] <GeolocControls.onStartGeoloc> Failed to stop the geolocation tracking process :`, pException);
            setLocalEnabled(false);
            toast.error(
              `Failed to start tracking your geolocation`,
              {
                progress: undefined,
              },
            );
          },
        );
    }
  };

  const onStopGeoloc = (pEvent: CustomEvent<ToggleChangeEventDetail>): void => {
    console.log(`[DEBUG] <GeolocControls.onStopGeoloc> Stopping the geolocation tracking process...`);
    setLocalEnabled(false);
    stopTracker()
      .then(
        (pState) => {
          console.log('[INFO ] <GeolocControls.onStopGeoloc> Stopped tracking geolocation :', pState);
          toast.success(
            `Stopped tracking your geolocation`,
            {
              progress: undefined,
            },
          );
        },
      )
      .catch(
        (pException) => {
          console.error(`[ERROR] <GeolocControls.onStopGeoloc> Failed to stop the geolocation tracking process :`, pException);
          setLocalEnabled(true);
          toast.error(
            `Failed to stop tracking your geolocation`,
            {
              progress: undefined,
            },
          );
        },
      );
  };

  const onToggleGeoloc = (pEvent: CustomEvent<ToggleChangeEventDetail>): void => {
    if(ready !== undefined && ready) {
      if(enabled !== undefined && enabled && localEnabled) {
        onStopGeoloc(pEvent);
      } else if(enabled === undefined || (!enabled && !localEnabled)) {
        onStartGeoloc(pEvent);
      }
    }
  };

  useEffect(
    () => {
      if(ready === undefined || enabled === undefined) {
        setLocalEnabled(false);
      } else {
        setLocalEnabled(ready && enabled);
      }
    },
    [
      ready,
      enabled,
    ],
  );

  return (
    <IonItem>
      <IonLabel>
        {localEnabled ? 'Stop' : 'Start'}
      </IonLabel>
      <IonToggle
        disabled={ready === true && localEnabled !== enabled}
        checked={localEnabled}
        onIonChange={onToggleGeoloc}
      />
    </IonItem>
  );
};

/* Export GeolocControls component --------------------- */
export default GeolocControls;
