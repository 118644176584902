/* Framework imports ----------------------------------- */
import React from 'react';

/* Module imports -------------------------------------- */

/* Component imports ----------------------------------- */
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonFooter,
} from '@ionic/react';

/* Type imports ---------------------------------------- */
import type { RefresherEventDetail } from '@ionic/core';

/* Styling imports ------------------------------------- */
import './PageLayout.css';

/* PageLayout component prop types --------------------- */
interface PageLayoutProps {
  children: React.ReactNode;
  backButtonLink?: string;
  title?: string;
  menu?: boolean;
  onRefresh?: () => Promise<unknown>;
  footer?: React.ReactNode | null;
}

const PageLayout: React.FC<PageLayoutProps> = (
  {
    children,
    backButtonLink,
    title,
    menu,
    onRefresh,
    footer,
  },
) => {
  const onRefreshPage = (pEvent: CustomEvent<RefresherEventDetail>) => {
    onRefresh && onRefresh().finally(
      () => {
        pEvent.detail.complete();
      },
    );
  };

  return (
    <IonPage>
      {
        (backButtonLink !== undefined || title !== undefined || menu !== undefined) && (
          <IonHeader>
            <IonToolbar>
              {
                backButtonLink !== undefined && (
                  <IonButtons slot="start">
                    <IonBackButton
                      defaultHref={backButtonLink}
                      text="Retour"
                    />
                  </IonButtons>
                )
              }
              {
                menu !== undefined &&
                  <IonButtons slot="end">
                    <IonMenuButton />
                  </IonButtons>
              }
              {
                title !== undefined &&
                  <IonTitle>
                    {title}
                  </IonTitle>
              }
            </IonToolbar>
          </IonHeader>
        )
      }
      <IonContent fullscreen>
        {
          title !== undefined && (
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">
                  {title}
                </IonTitle>
              </IonToolbar>
            </IonHeader>
          )
        }
        {
          onRefresh &&
            <IonRefresher
              slot="fixed"
              onIonRefresh={onRefreshPage}
            >
              <IonRefresherContent />
            </IonRefresher>
        }
        {children}
        <div style={{ height: '100px' }} />
      </IonContent>
      {
        footer !== undefined &&
          <IonFooter>
            {footer}
          </IonFooter>
      }
    </IonPage>
  );
};

/* Export PageLayout component ------------------------- */
export default PageLayout;
