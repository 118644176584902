/* Module imports -------------------------------------- */
import { Http } from '@capacitor-community/http';
import ERP_SERVER_CONFIG from '../config/erpServer.config.json';

/* Type imports ---------------------------------------- */
import type {
  HttpResponse,
  HttpOptions,
} from '@capacitor-community/http';
import type { OdooError } from './../types/OdooError';

/* Type definitions ------------------------------------ */
interface LoginCreds {
  email: string;
  password: string;
}

/* Authentication helper functions --------------------- */
const odooRequest = async (pRoute: string, pBody: Record<string, unknown>): Promise<HttpResponse> => {
  try {
    const lRequestOptions: HttpOptions = {
      url: `${ERP_SERVER_CONFIG.ODOO_HOST}${pRoute}`,
      method: 'POST',
      data: pBody,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const lRequestResponse: HttpResponse = await Http.request(lRequestOptions);

    return lRequestResponse;
  } catch(pException) {
    console.error(`[ERROR] <odooRequest> Odoo request (${pRoute}) to ${ERP_SERVER_CONFIG.ODOO_HOST} failed :`, pException);
    throw pException;
  }
};

export const logout = async (): Promise<HttpResponse> => {
  try {
    const lRequestResult: HttpResponse = await odooRequest(
      '/web/session/signout',
      {},
    );

    const lOdooError: OdooError | undefined = (lRequestResult.data as Record<string, unknown>)?.error as OdooError | undefined;
    if(lOdooError !== undefined && lOdooError !== null) {
      console.error(`[ERROR] <logout> Odoo server sent an error response :`, lOdooError);
      throw new Error(lOdooError.message);
    }

    return lRequestResult;
  } catch(pException) {
    console.error(`[ERROR] <logout> Failed to logout user ${undefined} from ${ERP_SERVER_CONFIG.ODOO_HOST} :`, pException);
    throw pException;
  }
};

export const login = async (pLoginCreds: LoginCreds): Promise<HttpResponse> => {
  try {
    const lRequestResult: HttpResponse = await odooRequest(
      '/web/session/authenticate',
      {
        params: {
          login: pLoginCreds.email,
          password: pLoginCreds.password,
          db: ERP_SERVER_CONFIG.ODOO_DATABASE,
        },
      },
    );

    const lOdooError: OdooError | undefined = (lRequestResult.data as Record<string, unknown>)?.error as OdooError | undefined;
    if(lOdooError !== undefined && lOdooError !== null) {
      console.error(`[ERROR] <login> Odoo server sent an error response :`, lOdooError);

      const lError: Error = new Error();

      if(lOdooError.data.name === 'odoo.exceptions.AccessDenied') {
        lError.message = 'Wrong user e-mail or password';
      }

      throw lError;
    }

    console.log(`[INFO ] <login> Successfully logged user ${pLoginCreds.email} into ${ERP_SERVER_CONFIG.ODOO_HOST} :`, JSON.stringify(lRequestResult));

    return lRequestResult;
  } catch(pException) {
    console.error(`[ERROR] <login> Failed to authenticate user ${pLoginCreds.email} to ${ERP_SERVER_CONFIG.ODOO_HOST} :`, pException);
    throw pException;
  }
};
