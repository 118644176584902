/* Framework imports ----------------------------------- */
import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

/* Module imports -------------------------------------- */
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
import { BACKGROUND_GEOLOC_CONFIG } from '../../config/backgroundGeolocation.config';
import BackgroundGeolocationManager from '../../helpers/BackgroundGeolocationManager';
import {
  useAppDispatch,
  useAuthInfo,
} from '../../redux/hooks';
import { setAuthInfo } from '../../redux/slices/authSlice';

/* Component imports ----------------------------------- */
import { IonRouterOutlet } from '@ionic/react';
import GeolocPage from '../../pages/GeolocPage/GeolocPage';
import SignInPage from '../../pages/SignInPage/SignInPage';
import RidePage from '../../pages/RidePage/RidePage';
import RidesListPage from '../../pages/RidesListPage/RidesListPage';
import SideMenu from '../SideMenu/SideMenu';

/* type imports ---------------------------------------- */
import type { AuthData } from '../../types/AuthData';

/* Helper functions ------------------------------------ */

/* MainLayout component prop types --------------------- */
interface MainLayoutProps {}

/* MainLayout component -------------------------------- */
const MainLayout: React.FC<MainLayoutProps> = () => {
  const authInfo = useAuthInfo();
  const dispatch = useAppDispatch();

  const afterLogin = async (pAuthData: AuthData): Promise<void> => {
    try {
      dispatch(setAuthInfo(pAuthData));

      console.log(`[DEBUG] <MAinLayout.afterLogin> Auth data received :`, pAuthData);

      if(
        (
          Capacitor.getPlatform() === 'android' ||
          Capacitor.getPlatform() === 'ios'
        ) &&
        Capacitor.isPluginAvailable('BackgroundGeolocation')
      ) {
        await BackgroundGeolocationManager.startGeolocationTracker(pAuthData);
      }
    } catch(pException) {
      console.error(`[ERROR] <MainLayout.afterLogin> Failed to reset the auth information & stop the tracker :`, pException);
      throw pException;
    }
  };

  const afterLogout = async (): Promise<void> => {
    try {
      dispatch(
        setAuthInfo(
          {
            isLoggedIn: false,
            authToken: null,
            authUID: null,
            sessionID: null,
          },
        ),
      );

      if(
        (
          Capacitor.getPlatform() === 'android' ||
          Capacitor.getPlatform() === 'ios'
        ) &&
        Capacitor.isPluginAvailable('BackgroundGeolocation')
      ) {
        await BackgroundGeolocationManager.stopGeolocationTracker();

        await BackgroundGeolocation.setConfig(BACKGROUND_GEOLOC_CONFIG);
      }

    } catch(pException) {
      console.error(`[ERROR] <MainLayout.afterLogout> Failed to reset the auth information & stop the tracker :`, pException);
      throw pException;
    }
  };

  const isLoggedIn: boolean = authInfo?.isLoggedIn ?? false;

  return (
    <>
      <SideMenu afterLogout={afterLogout} />
      <IonRouterOutlet id="main">
        <Route
          exact
          path="/geoloc"
        >
          {
            isLoggedIn ?
              <GeolocPage /> :
              <Redirect to="/login" />
          }
        </Route>
        <Route
          exact
          path="/rides"
        >
          {
            isLoggedIn ?
              <RidesListPage /> :
              <Redirect to="/login" />
          }
        </Route>
        <Route
          exact
          path="/rides/:rideID"
        >
          {
            isLoggedIn ?
              <RidePage /> :
              <Redirect to="/login" />
          }
        </Route>
        <Route
          exact
          path="/login"
        >
          {
            isLoggedIn ?
              <Redirect to="/rides" /> :
              <SignInPage
                isLoggedIn={isLoggedIn}
                afterLogin={afterLogin}
                afterLogout={afterLogout}

              />
          }
        </Route>
        <Route
          exact
          path="/"
        >
          {
            isLoggedIn ?
              <Redirect to="/rides" /> :
              <Redirect to="/login" />
          }
        </Route>
      </IonRouterOutlet>
    </>
  );
};

/* Export MainLayout component ------------------------- */
export default MainLayout;
