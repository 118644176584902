/* Definition of the GeoActivityType enum -------------- */
export enum GeoActivityType {
  STILL = `still`,
  WALKING = `walking`,
  ON_FOOT = `on_foot`,
  RUNNING = `running`,
  ON_BICYCLE = `on_bicycle`,
  IN_VEHICLE = `in_vehicle`,
  UNKNOWN = `unknown`,
}
