/* Framework imports ----------------------------------- */
import React, { useMemo } from 'react';
import * as Luq from '@bold/luq-client';

/* Module imports -------------------------------------- */
import { useAuthInfo } from '../../redux/hooks';
import { useRidesListQuery } from '../../apollo/queries';
import {
  formatDateForSQL,
  getTodayLimits, 
} from '../../helpers/dateHelpers';
import { luqOdooCompiler } from '../../helpers/luqOdooCompiler';

/* Component imports ----------------------------------- */
import { IonItemGroup } from '@ionic/react';
import PageLayout from '../../layouts/PageLayout/PageLayout';
import RideList from './RideList';

/* Style imports --------------------------------------- */

/* Type imports ---------------------------------------- */
import type { RideFragment } from '../../apollo/queries/types';

/* RidesListPage component prop types ------------------ */
interface RidesListPageProps {}

/* RidesListPage component ----------------------------- */
const RidesListPage: React.FC<RidesListPageProps> = () => {
  const authInfo = useAuthInfo();

  const [ todayEarlyLimit, todayLateLimit ] = getTodayLimits();
  console.log(`[DEBUG] <RideListPage> todayEarlyLimit :`, todayEarlyLimit);
  console.log(`[DEBUG] <RideListPage> todayLateLimit :`, todayLateLimit);

  const currentFilterQuery = useMemo<JSX.Element>(
    () => {
      return (
        <Luq.And>
          <Luq.Eq
            field="driverId"
            value={authInfo?.authUID}
          />
          <Luq.Eq
            field="state"
            value="wip"
          />
        </Luq.And>
      );
    },
    [
      authInfo?.authUID,
    ],
  );

  const todayFilterQuery = useMemo<JSX.Element>(
    () => {
      return (
        <Luq.And>
          <Luq.Eq
            field="driverId"
            value={authInfo?.authUID}
          />
          <Luq.Gt
            field="startDate"
            value={formatDateForSQL(todayEarlyLimit)}
          />
          <Luq.Lt
            field="startDate"
            value={formatDateForSQL(todayLateLimit)}
          />
          <Luq.Eq
            field="state"
            value="waiting"
          />
        </Luq.And>
      );
    },
    [
      todayEarlyLimit,
      todayLateLimit,
      authInfo?.authUID,
    ],
  );

  const plannedFilterQuery = useMemo<JSX.Element>(
    () => {
      return (
        <Luq.And>
          <Luq.Eq
            field="driverId"
            value={authInfo?.authUID}
          />
          <Luq.Gt
            field="startDate"
            value={formatDateForSQL(todayLateLimit)}
          />
          <Luq.NotIn
            field="state"
            values={[ 'confirmed', 'complete' ]}
          />
        </Luq.And>
      );
    },
    [
      authInfo?.authUID,
      todayLateLimit,
    ],
  );

  const completedRidesFilterQuery = useMemo<JSX.Element>(
    () => {
      return (
        <Luq.And>
          <Luq.Eq
            field="driverId"
            value={authInfo?.authUID}
          />
          <Luq.Gt
            field="endDate"
            value={formatDateForSQL(new Date(new Date(todayEarlyLimit).setDate(todayEarlyLimit.getDate() - 2)))}
          />
          <Luq.In
            field="state"
            values={[ 'confirmed', 'complete' ]}
          />
        </Luq.And>
      );
    },
    [
      todayEarlyLimit,
      authInfo?.authUID,
    ],
  );

  const {
    loading: loadingRides,
    error: errorRides,
    data: dataRides,
    refetch: refetchRides,
  } = useRidesListQuery(
    {
      variables: {
        currentFilter: luqOdooCompiler(currentFilterQuery),
        todayFilter: luqOdooCompiler(todayFilterQuery),
        plannedFilter: luqOdooCompiler(plannedFilterQuery),
        doneTodayFilter: luqOdooCompiler(completedRidesFilterQuery),
      },
    },
  );

  const currentRides: RideFragment[] = dataRides?.currentRide ?? [];
  const todayRides: RideFragment[] = dataRides?.todayRides ?? [];
  const plannedRides: RideFragment[] = dataRides?.plannedRides ?? [];
  const doneTodayRides: RideFragment[] = dataRides?.doneTodayRides ?? [];

  return (
    <PageLayout
      menu
      title="Rides"
      onRefresh={
        async () => {
          await refetchRides(
            {
              currentFilter: luqOdooCompiler(currentFilterQuery),
              todayFilter: luqOdooCompiler(todayFilterQuery),
              plannedFilter: luqOdooCompiler(plannedFilterQuery),
              doneTodayFilter: luqOdooCompiler(completedRidesFilterQuery),
            },
          );
        }
      }
    >
      <IonItemGroup>
        {
          currentRides.length > 0 &&
            <RideList
              title={`Ride${currentRides.length > 1 ? 's' : ''} in progress`}
              rides={currentRides}
              loading={loadingRides}
            />
        }
        <RideList
          title="Today's rides"
          rides={todayRides}
          loading={loadingRides}
        />
        <RideList
          title="Planned rides"
          rides={plannedRides}
          loading={loadingRides}
        />
        <RideList
          title="Completed rides"
          rides={doneTodayRides}
          loading={loadingRides}
        />
      </IonItemGroup>
    </PageLayout>
  );
};

/* Export RidesListPage component ---------------------- */
export default RidesListPage;
