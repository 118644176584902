/* Framework imports ----------------------------------- */
import React, { useState } from 'react';

/* Module imports -------------------------------------- */
import { toast } from 'react-toastify';
import { menuController } from '@ionic/core';
import { login } from '../../helpers/authentication';

/* Component imports ----------------------------------- */
import {
  IonButton,
  IonInput,
  IonItem,
  IonIcon,
  IonRow,
  IonCol,
  IonImg,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from '@ionic/react';
import {
  lockOpenOutline,
  lockClosedOutline,
} from 'ionicons/icons';
import PageLayout from '../../layouts/PageLayout/PageLayout';

/* Type imports ---------------------------------------- */
import type { InputChangeEventDetail } from '@ionic/core';
import type { OdooAuthResult } from '../../types/OdooAuthResult';
import type { OdooResponse } from '../../types/OdooResponse';
import type { AuthData } from '../../types/AuthData';

/* Styling imports ------------------------------------- */
import './SignInPage.css';

/* SignInPage component prop types --------------------- */
interface SignInPageProps {
  afterLogin?: (pAuthData: AuthData) => void;
  afterLogout?: () => void;
  onFailure?: (pError: Error) => void;
  isLoggedIn?: boolean;
}

/* SignInPage component -------------------------------- */
const SignInPage: React.FC<SignInPageProps> = (
  {
    afterLogin,
    onFailure,
    isLoggedIn = false,
  },
) => {
  const [ email, setEmail ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');
  const [ loginInProgress, setLoginInProgress ] = useState<boolean>(false);

  const tryLogin = (): void => {
    login(
      {
        email,
        password,
      },
    )
      .then(
        (pResult) => {
          const lLoginResult: OdooAuthResult = (
            pResult.data as OdooResponse<OdooAuthResult>
          ).result;

          console.log(`[DEBUG] <SignInPage.tryLogin> Odoo response :`, lLoginResult);

          if(afterLogin !== undefined) {
            afterLogin(
              {
                isLoggedIn: true,
                authToken: lLoginResult.auth_token,
                authUID: lLoginResult.uid,
                sessionID: lLoginResult.session_id,
              },
            );
          }

          toast.success(
            `⚠️ Successfully logged you in`,
            {
              progress: undefined,
            },
          );
        },
      )
      .catch(
        (pException) => {
          const lError = pException as Error;

          if(onFailure !== undefined) {
            onFailure(lError);
          }

          if(afterLogin !== undefined) {
            afterLogin(
              {
                isLoggedIn: false,
                authToken: null,
                authUID: null,
                sessionID: null,
              },
            );
          }

          const lErrorStr: string = `Log-in failed : ${lError.message}`;

          toast.error(
            `⚠️ ${lErrorStr}`,
            {
              progress: undefined,
            },
          );
        },
      )
      .finally(
        () => {
          setLoginInProgress(false);
        },
      );
  };

  const onEmailChanged = (pEvent: CustomEvent<InputChangeEventDetail>) => {
    setEmail(pEvent.detail.value ?? '');
  };

  const onPasswordChanged = (pEvent: CustomEvent<InputChangeEventDetail>) => {
    setPassword(pEvent.detail.value ?? '');
  };

  useIonViewDidEnter(
    () => {
      menuController.enable(false, 'main-menu')
        .then(
          () => {
            // console.log(`[DEBUG] <SignInPage> Successfully disabled the side menu`);
          },
        )
        .catch(
          (pException) => {
            console.error(`[ERROR] <SignInPage> Failed to disable the side menu :`, pException);
          },
        );
    },
    [
      isLoggedIn,
    ],
  );

  useIonViewDidLeave(
    () => {
      menuController.enable(true, 'main-menu')
        .then(
          () => {
            // console.log(`[DEBUG] <SignInPage> Successfully enabled the side menu`);
          },
        )
        .catch(
          (pException) => {
            console.error(`[ERROR] <SignInPage> Failed to enable the side menu :`, pException);
          },
        );
    },
  );

  return (
    <PageLayout>
      <div className="SignInFormContainer">
        <IonRow>
          <IonCol>
            <IonImg
              // TODO : Change logo if light mode
              src="/assets/logo/Communicor_Logo_C_only_W&B.png"
              style={
                {
                  height: '100px',
                }
              }
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">
                E-mail
              </IonLabel>
              <IonInput
                type="email"
                value={email}
                onIonChange={onEmailChanged}
                placeholder="E-mail address..."
                required
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">
                Password
              </IonLabel>
              <IonInput
                type="password"
                value={password}
                onIonChange={onPasswordChanged}
                placeholder="Password..."
                required
                onKeyPress={
                  (pEvent) => {
                    if(pEvent.key === 'Enter') {
                      tryLogin();
                    }
                  }
                }
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton
              type="submit"
              onClick={tryLogin}
              disabled={!email || !password || loginInProgress}
              expand="block"
            >
              {
                (!email || !password) ?
                  <IonIcon icon={lockClosedOutline} /> :
                  <IonIcon icon={lockOpenOutline} />
              }
              &nbsp;
              Sign in
            </IonButton>
          </IonCol>
        </IonRow>
      </div>
    </PageLayout>
  );
};

/* Export SignInPage ----------------------------------- */
export default SignInPage;
