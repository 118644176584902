/* Framework imports ----------------------------------- */

/* Module imports -------------------------------------- */
import * as RTK from '@reduxjs/toolkit';
import * as RTKQuery from '@reduxjs/toolkit/query';
import { persistStore } from 'redux-persist';

/* Redux slice imports --------------------------------- */
import { persistedAuthReducer } from './slices/authSlice';
import geolocationReducer from './slices/geolocationSlice';

/* Store configuration --------------------------------- */
export const store = RTK.configureStore(
  {
    reducer: {
      auth: persistedAuthReducer,
      geolocation: geolocationReducer,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware();
    },
  },
);

export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
RTKQuery.setupListeners(store.dispatch);

/* Type exports ---------------------------------------- */
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
