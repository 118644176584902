/* Internal variables ---------------------------------- */
const sDefaultDateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
  hour: 'numeric',
  minute: '2-digit',
};

/* Date helper functions ------------------------------- */
export const getTodayLimits = (): [Date, Date] => {
  const todayEarlyLimit = new Date();
  todayEarlyLimit.setHours(0);
  todayEarlyLimit.setMinutes(0);
  todayEarlyLimit.setSeconds(0);

  const todayLateLimit = new Date(todayEarlyLimit);
  todayLateLimit.setDate(todayLateLimit.getDate() + 1);

  return [
    todayEarlyLimit,
    todayLateLimit,
  ];
};

export const isValidDate = (pDate: unknown): pDate is Date => {
  return pDate instanceof Date && !isNaN(pDate.getTime());
};

/* Date format helper functions ------------------------ */
const dateFromOdooDateStr = (pOdooDateStr: string): Date => {
  /* Received format is non-standard, look like :
   * "2023-04-14 07:00:00+02:00"
   * and is UTC.
   *
   * Note the absence of the 'T' separator and 'Z' indicator.
   */

  pOdooDateStr = `${pOdooDateStr}Z`;

  const lYear: number = parseInt(pOdooDateStr.slice(0, 4));
  const lMonth: number = parseInt(pOdooDateStr.slice(5, 7)) - 1;
  const lDate: number = parseInt(pOdooDateStr.slice(8, 10));
  const lHours: number = parseInt(pOdooDateStr.slice(11, 13));
  const lMinutes: number = parseInt(pOdooDateStr.slice(14, 16));
  const lSeconds: number = parseInt(pOdooDateStr.slice(17, 19));

  const lTimeZoneSign: string = pOdooDateStr.slice(19, 20);

  const lTimeZoneHoursOffset: number = lTimeZoneSign === '-' ?
    -parseInt(pOdooDateStr.slice(20, 22)) :
    parseInt(pOdooDateStr.slice(20, 22));
  const lTimeZoneMinutesOffset: number = parseInt(pOdooDateStr.slice(23, 25));

  const lUTCDate: Date = new Date(
    Date.UTC(
      lYear,
      lMonth,
      lDate,
      lHours - lTimeZoneHoursOffset,
      lMinutes - lTimeZoneMinutesOffset,
      lSeconds,
    ),
  );

  // console.log(
  //   pOdooDateStr,
  //   {
  //     lYear,
  //     lMonth,
  //     lDate,
  //     lHours,
  //     lMinutes,
  //     lSeconds,
  //     lTimeZoneHoursOffset,
  //     lTimeZoneMinutesOffset,
  //   },
  //   lUTCDate,
  //   lTimeZoneSign,
  // );

  return lUTCDate;
};

export const formatDate = (pDate: Date, pOptions?: Intl.DateTimeFormatOptions): string => {
  return pDate.toLocaleDateString(
    'en-UK',
    {
      ...sDefaultDateFormatOptions,
      ...pOptions,
    },
  );
};

export const formatDateStr = (pDateStr: string, pOptions?: Intl.DateTimeFormatOptions): string => {
  return formatDate(new Date(pDateStr), pOptions);
};

export const formatISODateStrForSQL = (isoDateStr: string): string => {
  /* Exported format is '%Y-%m-%d %H:%M:%S' */

  return isoDateStr
    .slice(0, 19)
    .replace(' ', 'T');
};

export const formatDateForSQL = (date: Date): string => {
  /* Exported format is '%Y-%m-%d %H:%M:%S' */

  return formatISODateStrForSQL(date.toISOString());
};

const formatDateForMobile = (pDate: Date): string => {
  /* Exported format is '%Y-%m-%dT%H:%M:%S' */

  /* This is especially useful for iOS
   * See : https://stackoverflow.com/a/13363791/8007661
   */

  // console.log(`[DEBUG] <formatDateForMobile> pDate :`, pDate);

  return pDate.toISOString();
};

export const formatDateStrForMobile = (pDateStr: string): string => {
  /* This is especially useful for iOS
   * See : https://stackoverflow.com/a/13363791/8007661
   */

  // console.log(`[DEBUG] <formatDateStrForMobile> pDateStr :`, pDateStr);

  // return formatDateForMobile(new Date(pDateStr.replace(' ', 'T')));
  return formatDateForMobile(dateFromOdooDateStr(pDateStr));
};

/* Date difference format helpers ---------------------- */
export const formatDelta = (pDeltaMs: number): string => {
  const time: Date = new Date(pDeltaMs);
  const hours: number = time.getUTCHours();
  const minutes: number = time.getUTCMinutes();
  const seconds: number = time.getUTCSeconds();
  const milliseconds: number = time.getUTCMilliseconds();

  return `${hours}:${minutes}:${seconds}:${milliseconds}`;
};
