/* Framework imports ----------------------------------- */
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Capacitor } from '@capacitor/core';

/* Module imports -------------------------------------- */
import { toast } from 'react-toastify';
import BackgroundGeolocationManager from '../../helpers/BackgroundGeolocationManager';
import {
  formatDateStr,
  formatDateStrForMobile,
  formatDelta,
  formatISODateStrForSQL,
  isValidDate,
} from '../../helpers/dateHelpers';
import { useAuthInfo } from '../../redux/hooks';
import { useRideQuery } from '../../apollo/queries';
import {
  useStartRideMutation,
  useCompleteRideMutation,
} from '../../apollo/mutations';

/* Component imports ----------------------------------- */
import {
  IonAlert,
  IonButton,
} from '@ionic/react';

/* Configuration imports ------------------------------- */

/* Style imports --------------------------------------- */

/* Type imports ---------------------------------------- */
import type { RideFragment } from '../../apollo/queries/types';
import type { State } from '@transistorsoft/capacitor-background-geolocation';

/* Type declarations ----------------------------------- */
interface RidePageFABSettings {
  buttonText: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
}

interface RidePageModalFormData {
  kilometers: string;
  date: string;
  place: string;
}

/* RidePageFAB component prop types -------------------- */
interface RidePageFABProps {
  ride: RideFragment;
  refetchRide: ReturnType<typeof useRideQuery>['refetch'];
  disabled?: boolean;
}

/* RidePageFAB component ------------------------------- */
const RidePageFAB: React.FC<RidePageFABProps> = (
  {
    ride,
    refetchRide,
    disabled = false,
  },
) => {
  const [ startRideModalOpen, setStartRideModalOpen ] = useState<boolean>(false);
  const [ endRideModalOpen, setEndRideModalOpen ] = useState<boolean>(false);
  const [ loadingStartRide, setLoadingStartRide ] = useState<boolean>(false);
  const [ loadingEndRide, setLoadingEndRide ] = useState<boolean>(false);

  const [ firstStartWarning, setFirstStartWarning ] = useState<boolean>(true);

  const authData = useAuthInfo();

  const [
    startRideCall,
    {
      loading: loadingStartRideMutation,
      data: startRideData,
      error: startRideError,
    },
  ] = useStartRideMutation();
  const [
    completeRideCall,
    {
      loading: loadingCompleteRideMutation,
      data: completeRideData,
      error: completeRideError,
    },
  ] = useCompleteRideMutation();

  const startRide = async (
    pKilometers: number,
    pDate: Date,
    pPlaceStr: string,
  ): Promise<void> => {
    const lISODateStr: string = pDate.toISOString();
    console.log(
      `[DEBUG] <RidePageFAB.startRide> Arguments :`,
      {
        kilometers: pKilometers,
        date: pDate,
        isoDate: lISODateStr,
        place: pPlaceStr,
      },
    );

    if(authData === null) {
      console.error(`[ERROR] <RidePageFAB.startRide> Authentication info absent, can't start geolocation process`);
      throw new Error(`Authentication info absent, can't start geolocation process`);
    }

    setLoadingStartRide(true);

    let lGeolocationTrackingState: State | null = null;

    try {
      /* Start the ride */
      try {
        await startRideCall(
          {
            variables: {
              id: ride.id,
              currentDate: formatISODateStrForSQL(lISODateStr),
              currentPlace: pPlaceStr,
              currentKilometers: pKilometers,
            },
          },
        );
      } catch(pException) {
        console.error(`[ERROR] <RidePageFAB.startRide> Failed to start the ride :`, pException);
        toast.error(
          `⚠️ Failed to start the ride`,
          {
            progress: undefined,
          },
        );
        throw pException;
      }

      /* Refetch the ride */
      try {
        await refetchRide({ id: ride.id });
      } catch(pException) {
        console.error(`[ERROR] <RidePageFAB.startRide> Failed to refresh the ride's data :`, pException);
        toast.error(
          `⚠️ Failed to refresh the ride's data`,
          {
            progress: undefined,
          },
        );
        throw pException;
      }

      /* Start Background geolocation tracking process */
      if(
        (
          Capacitor.getPlatform() === 'android' ||
          Capacitor.getPlatform() === 'ios'
        ) &&
        Capacitor.isPluginAvailable('BackgroundGeolocation')
      ) {
        try {
          lGeolocationTrackingState = await BackgroundGeolocationManager.startGeolocationTracker(authData);
        } catch(pException) {
          console.error(`[ERROR] <RidePageFAB.startRide> Failed to start background geolocation tracking :`, pException);
          toast.error(
            `⚠️ Failed to start background geolocation tracking`,
            {
              progress: undefined,
            },
          );
          throw pException;
        }
      }

      toast.success(
        `✅ Ride started !`,
        {
          progress: undefined,
        },
      );
    } catch(pException) {
      console.error(`[ERROR] <RidePageFAB.startRide> Something went wrong while starting ride ${ride.id} :`, pException);

      /* Stop the background geolocation tracking process */
      if(
        lGeolocationTrackingState !== null &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lGeolocationTrackingState.enabled === true &&
        (
          Capacitor.getPlatform() === 'android' ||
          Capacitor.getPlatform() === 'ios'
        ) &&
        Capacitor.isPluginAvailable('BackgroundGeolocation')
      ) {
        try {
          lGeolocationTrackingState = await BackgroundGeolocationManager.stopGeolocationTracker();
        } catch(pException) {
          console.error(`[ERROR] <RidePageFAB.startRide> Failed to stop background geolocation tracking :`, pException);
          toast.error(
            `⚠️ Failed to stop background geolocation tracking`,
            {
              progress: undefined,
            },
          );
          throw pException;
        }
      }

      throw pException;
    } finally {
      setLoadingStartRide(false);
    }
  };

  const endRide = async (
    pKilometers: number,
    pDate: Date,
    pPlaceStr: string,
  ): Promise<void> => {
    const lISODateStr: string = pDate.toISOString();
    console.log(
      `[DEBUG] <RidePageFAB.endRide> Arguments :`,
      {
        kilometers: pKilometers,
        date: pDate,
        isoDate: lISODateStr,
        place: pPlaceStr,
      },
    );

    setLoadingEndRide(true);

    let lGeolocationTrackingState: State | null = null;

    try {
      /* Start the ride */
      try {
        await completeRideCall(
          {
            variables: {
              id: ride.id,
              currentDate: formatISODateStrForSQL(lISODateStr),
              currentPlace: pPlaceStr,
              currentKilometers: pKilometers,
            },
          },
        );
      } catch(pException) {
        console.error(`[ERROR] <RidePageFAB.endRide> Failed to end the ride :`, pException);
        toast.error(
          `⚠️ Failed to end the ride`,
          {
            progress: undefined,
          },
        );
        throw pException;
      }

      /* Refetch the ride */
      try {
        await refetchRide({ id: ride.id });
      } catch(pException) {
        console.error(`[ERROR] <RidePageFAB.endRide> Failed to refresh the ride's data :`, pException);
        toast.error(
          `⚠️ Failed to refresh the ride's data`,
          {
            progress: undefined,
          },
        );
        throw pException;
      }

      /* End Background geolocation tracking process */
      if(
        lGeolocationTrackingState !== null &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lGeolocationTrackingState.enabled === true &&
        (
          Capacitor.getPlatform() === 'android' ||
          Capacitor.getPlatform() === 'ios'
        ) &&
        Capacitor.isPluginAvailable('BackgroundGeolocation')
      ) {
        try {
          lGeolocationTrackingState = await BackgroundGeolocationManager.stopGeolocationTracker();
        } catch(pException) {
          console.error(`[ERROR] <RidePageFAB.endRide> Failed to start background geolocation tracking :`, pException);
          toast.error(
            `⚠️ Failed to start background geolocation tracking`,
            {
              progress: undefined,
            },
          );
          throw pException;
        }
      }

      toast.success(
        `✅ Ride completed !`,
        {
          progress: undefined,
        },
      );
    } catch(pException) {
      console.error(`[ERROR] <RidePageFAB.endRide> Something went wrong while ending ride ${ride.id} :`, pException);
      throw pException;
    } finally {
      setLoadingEndRide(false);
    }
  };

  const showStartRideModal: React.MouseEventHandler<HTMLIonButtonElement> = useCallback(
    () => {
      console.log(`[DEBUG] <RidePageFAB.showStartRideModal> ride :`, ride);
      setStartRideModalOpen(true);
    },
    [
      ride,
    ],
  );

  const showEndRideModal: React.MouseEventHandler<HTMLIonButtonElement> = useCallback(
    () => {
      console.log(`[DEBUG] <RidePageFAB.showEndRideModal> ride :`, ride);
      setEndRideModalOpen(true);
    },
    [
      ride, 
    ],
  );

  const startRideHandler = (pFormData: RidePageModalFormData): void => {
    if(
      pFormData.date.length < 1 &&
      ride.startDate !== undefined &&
      ride.startDate !== null
    ) {
      pFormData.date = ride.startDate;
    }

    console.log(`[DEBUG] <RidePageFAB.startRideHandler> pFormData :`, pFormData);
    console.log(`[DEBUG] <RidePageFAB.startRideHandler> ride.startDate string :`, ride.startDate);
    console.log(`[DEBUG] <RidePageFAB.startRideHandler> ride.startDate formatted:`, formatDateStrForMobile(ride.startDate!));

    const lInputKilometers: number = parseInt(pFormData.kilometers, 10);
    const lInputStartDate: Date = new Date(pFormData.date.replaceAll(' ', 'T'));
    if(!isValidDate(lInputStartDate)) {
      console.error(`[ERROR] <RidePageFAB.startRideHandler> Input start date is invalid :`, pFormData.date, lInputStartDate);
      toast.error(`⚠️ Invalid input date : ${pFormData.date}`);
      return;
    }

    let lWarn: boolean = false;

    /** If the start of the ride doesn't match ERP data,
     * then show an error on first attempt,
     * and a warnign on others
     */
    const lToastFunction: typeof toast.warning = firstStartWarning === true ?
      toast.error :
      toast.warning;

    if(
      ride.startDate !== null &&
      ride.startDate !== undefined &&
      ride.startDate.length > 0
    ) {
      const lDeltaMs: number = new Date(`${ride.startDate}`).getTime() - new Date(pFormData.date).getTime();

      /**
       * The goal here is to check if the start date and the expected start date
       * are at most 1 hour appart
       */

      if(Math.abs(lDeltaMs) > (1 * 60 * 60 * 1000)) {
        lWarn = true;

        let lWarnText: string = '';
        if(lDeltaMs > 0) {
          lWarnText = `You started the ride ${formatDelta(lDeltaMs)} too early.`;
        } else if(lDeltaMs < 0) {
          lWarnText = `You started the ride ${formatDelta(-lDeltaMs)} too late.`;
        }

        if(firstStartWarning === true) {
          lWarnText = `${lWarnText} Try again to force starte this ride.`;
        }

        console[firstStartWarning === true ? 'error' : 'warn'](`[${firstStartWarning === true ? 'ERROR' : 'WARN '}] <RidePageFAB.startRideHandler> ${lWarnText}`);

        lToastFunction(
          `⚠️ ${lWarnText}`,
          {
            progress: undefined,
          },
        );
      }
    }

    if(
      !lWarn ||
      (lWarn && !firstStartWarning)
    ) {
      startRide(
        lInputKilometers,
        lInputStartDate,
        pFormData.place,
      )
        .catch(console.error);
    } else {
      setFirstStartWarning(false);
    }
  };

  const endRideHandler = (pFormData: RidePageModalFormData): void => {
    if(
      pFormData.date.length < 1 &&
          ride.endDate !== undefined &&
          ride.endDate !== null
    ) {
      pFormData.date = ride.endDate;
    }

    console.log(`[DEBUG] <RidePageFAB.endRideHandler> pFormData :`, pFormData);

    const lInputKilometers: number = parseInt(pFormData.kilometers, 10);
    const lInputEndDate: Date = new Date(pFormData.date.replaceAll(' ', 'T'));
    if(!isValidDate(lInputEndDate)) {
      console.error(`[ERROR] <RidePageFAB.endRideHandler> Input end date is invalid :`, pFormData.date, lInputEndDate);
      toast.error(`⚠️ Invalid input date : ${pFormData.date}`);
      return;
    }

    /* Chack that the end odometer is superior to the start odometer */
    if(
      ride.driverStartKilometer !== null &&
      ride.driverStartKilometer !== undefined &&
      ride.driverStartKilometer > lInputKilometers
    ) {
      toast.error(
        `⚠️ You started the ride at ${ride.driverStartKilometer}km, current kilometers should be superior to that value.`,
        {
          progress: undefined,
        },
      );

      return;
    }
    
    /* Check that the driver's end date is superior to the driver's start date */
    if(
      ride.driverStartDate !== null &&
      ride.driverStartDate !== undefined &&
      ride.driverStartDate.length > 0 &&
      new Date(ride.driverStartDate).getTime() > lInputEndDate.getTime()
    ) {

      /**
       * The goal here is to check if the driver's end date is later
       * than the driver's start date.
       */

      const lStartDateStr: string = formatDateStr(
        formatDateStrForMobile(ride.driverStartDate),
        {
          month: '2-digit',
        },
      );

      toast.error(
        `⚠️ You started the ride at ${lStartDateStr}, drop down date should be later.`,
        {
          progress: undefined,
        },
      );
    } else {
      endRide(
        lInputKilometers,
        lInputEndDate,
        pFormData.place,
      )
        .catch(console.error);
    }
  };

  const buttonSettings: RidePageFABSettings | null = useMemo<RidePageFABSettings | null>(
    () => {
      switch(ride.state.key) {
        case 'waiting':
          return {
            buttonText: 'Start ride',
            disabled: false,
            onClick: showStartRideModal,
          };
        case 'wip':
          return {
            buttonText: 'End ride',
            disabled: false,
            onClick: showEndRideModal,
          };
        default:
          return null;
      }
    },
    [
      ride.state.key,
      showEndRideModal,
      showStartRideModal,
    ],
  );

  if(buttonSettings === null) {
    return null;
  }

  console.log(`[DEBUG] <RidePageFAB> ride.driverStartDate (+ stripped) :`, ride.driverStartDate, ride.driverStartDate?.slice(0, -6));
  console.log(`[DEBUG] <RidePageFAB> ride.driverEndDate (+ stripped) :`, ride.driverEndDate, ride.driverEndDate?.slice(0, -6));
  console.log(`[DEBUG] <RidePageFAB> ride.startDate (+ stripped) :`, ride.startDate, ride.startDate?.slice(0, -6));
  console.log(`[DEBUG] <RidePageFAB> ride.endDate (+ stripped) :`, ride.endDate, ride.driverEndDate?.slice(0, -6));
  console.log(`[DEBUG] <RidePageFAB> ride.driverStartPlace :`, ride.driverStartPlace);
  console.log(`[DEBUG] <RidePageFAB> ride.driverEndPlace :`, ride.driverEndPlace);
  console.log(`[DEBUG] <RidePageFAB> ride.startPlace :`, ride.startPlace);
  console.log(`[DEBUG] <RidePageFAB> ride.endPlace :`, ride.endPlace);

  return (
    <>
      <IonButton
        expand="full"
        disabled={
          disabled ||
          buttonSettings.disabled ||
          loadingStartRideMutation ||
          loadingCompleteRideMutation ||
          loadingStartRide ||
          loadingEndRide
        }
        onClick={buttonSettings.onClick}
      >
        {buttonSettings?.buttonText}
      </IonButton>
      <IonAlert
        isOpen={startRideModalOpen}
        onDidDismiss={() => setStartRideModalOpen(false)}
        header="Start ride ?"
        // subHeader="Please give us the current kilometer count of your vehicle"
        message="Please give us the current kilometer count of your vehicle"
        buttons={
          [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                setStartRideModalOpen(false);
              },
            },
            {
              text: 'Start ride',
              handler: startRideHandler,
            },
          ]
        }
        inputs={
          [
            {
              name: 'kilometers',
              type: 'number',
              placeholder: 'Start kilometers',
            },
            {
              name: 'date',
              type: 'datetime-local',
              placeholder: 'Start date',
              value: ride.startDate !== undefined &&
                ride.startDate !== null &&
                ride.startDate.length > 0 ?
                ride.startDate?.slice(0, -6) : /* Strip the timezone offset */
                new Date().toISOString(),
            },
            {
              name: 'place',
              type: 'text',
              placeholder: 'Start place',
              value: ride.startPlace,
            },
          ]
        }
      />
      <IonAlert
        isOpen={endRideModalOpen}
        onDidDismiss={() => setEndRideModalOpen(false)}
        header="End ride ?"
        // subHeader="Please give us the current kilometer count of your vehicle"
        message="Please give us the current kilometer count of your vehicle"
        buttons={
          [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                setEndRideModalOpen(false);
              },
            },
            {
              text: 'End ride',
              handler: endRideHandler,
            },
          ]
        }
        inputs={
          [
            {
              name: 'kilometers',
              type: 'number',
              placeholder: 'End kilometers',
            },
            {
              name: 'date',
              type: 'datetime-local',
              placeholder: 'End date',
              value: ride.endDate !== undefined &&
                ride.endDate !== null &&
                ride.endDate.length > 0 ?
                ride.endDate?.slice(0, -6) : /* Strip the timezone offset */
                new Date().toISOString(),
            },
            {
              name: 'place',
              type: 'text',
              placeholder: 'End place',
              value: ride.endPlace,
            },
          ]
        }
      />
    </>
  );
};

/* Export RidePageFAB component ------------------------ */
export default RidePageFAB;
