/* Module imports -------------------------------------- */
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
import ERP_SERVER_CONFIG from './erpServer.config.json';

/* Type imports ---------------------------------------- */
import type { Config } from '@transistorsoft/capacitor-background-geolocation';

/* Notes ----------------------------------------------- */
/**
 * This configuration file is meant to define
 * the default configuration for the
 * @transistorsoft/capacitor-background-geolocation library.
 *
 * Please check their documentation before changing anything.
 */

/* ----------------------------------------------------- */
/* @transistorsoft/capacitor-background-geolocation library configuration */
export const BACKGROUND_GEOLOC_CONFIG: Config = {
  debug: false, // <-- enable this hear sounds for background-geolocation life-cycle.
  logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
  desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_NAVIGATION,
  distanceFilter: 5, /* 5 meters before an update event */

  stopAfterElapsedMinutes: 0,
  stopOnStationary: false,
  useSignificantChangesOnly: false,
  allowIdenticalLocations: true,
  preventSuspend: true,
  foregroundService: true, /* Default: false. Set true to make the plugin mostly immune to OS termination due to memory pressure from other apps. */

  // Activity Recognition
  stopTimeout: 5,
  // Application config
  stopOnTerminate: false,   // <-- Allow the background-service to continue tracking when user closes the app.
  startOnBoot: true,        // <-- Auto start tracking when device is powered-up.
  // HTTP / SQLite config
  url: `${ERP_SERVER_CONFIG.ODOO_HOST}/location`,
  // batchSync: false,       // <-- [Default: false] Set true to sync locations to server in a single HTTP request.
  // autoSync: true,         // <-- [Default: true] Set true to sync each location to server as it arrives.
  headers: {              // <-- Optional HTTP headers
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  // params: {               // <-- Optional HTTP params append to each HTTP request
  //   'auth_token': 'maybe_your_server_authenticates_via_token_YES?',
  // },
  // extras: {               // <-- Optional meta-data appended to each recorded location.
  //   'foo': 'bar',
  // },
  backgroundPermissionRationale: {
    title: `Allow ${process.env.APP_NAME ?? 'Communicor driver app'} to access this device's location even when closed or not in use.`,
    message: 'This app collects location data to enable recording your trips to work and calculate distance-travelled.',
    positiveAction: 'Allow',
    negativeAction: 'Cancel',
  },
  /**
   * Configure the initial tracking-state after
   * BackgroundGeolocation.start is called.
   * The plugin will immediately enter the tracking-state,
   * by-passing the stationary state.
   * If the device is not currently moving,
   * the stop-detection system will still engage.
   * After stopTimeout minutes without movement,
   * the plugin will enter the stationary state,
   * as usual.
   */
  isMoving: true,
};
