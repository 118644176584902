/* Framework imports ----------------------------------- */
import React from 'react';

/* Module imports -------------------------------------- */
import { useGeolocationData } from '../../redux/hooks';
import BackgroundGeolocationManager from '../../helpers/BackgroundGeolocationManager';

/* Component imports ----------------------------------- */
import PageLayout from '../../layouts/PageLayout/PageLayout';
import GeolocDataDisplay from '../../components/GeolocDataDisplay/GeolocDataDisplay';
import GeolocControls from './GeolocControls/GeolocControls';

/* Type imports ---------------------------------------- */

/* GeolocPage component -------------------------------- */
const GeolocPage: React.FC = () => {
  const geolocationData = useGeolocationData();

  console.log(`[DEBUG] <GeolocPage> geolocationData :`, geolocationData);

  return (
    <PageLayout
      menu
      title="Geolocation"
    >
      <GeolocDataDisplay
        ready={geolocationData?.ready ?? false}
        enabled={geolocationData?.enabled ?? false}
        isMoving={geolocationData?.isMoving ?? false}
        location={geolocationData?.location}
        activity={geolocationData?.activity}
      />
      <GeolocControls
        ready={geolocationData?.ready}
        enabled={geolocationData?.enabled}
        startTracker={BackgroundGeolocationManager.startGeolocationTracker}
        stopTracker={BackgroundGeolocationManager.stopGeolocationTracker}
      />
    </PageLayout>
  );
};

/* Export GeolocPage component ------------------------- */
export default GeolocPage;
