/* eslint-disable @typescript-eslint/no-unsafe-return */
/* Module import --------------------------------------- */
import {
  makeVar,
  ReactiveVar,
} from '@apollo/client';

const variables: {
  [key: string]: ReactiveVar<unknown>;
} = {};

type DataStoreId = 'memory' | 'sessionStorage' | 'localStorage';
type DataStore<T> = {
  getInitialValue: (key: string, defaultValue: T) => T;
  setValue: (key: string, value: T) => void;
};

const dataStores: {
  [key in DataStoreId]: DataStore<unknown>
} = {
  memory: {
    getInitialValue: (key: string, defaultValue) => defaultValue,
    setValue: () => {},
  },
  sessionStorage: {
    getInitialValue: (key: string, defaultValue) => {
      const storageItem = window.sessionStorage.getItem(key);

      if(storageItem === null) {
        window.sessionStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }

      return JSON.parse(storageItem);
    },
    setValue: (key: string, value) => {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    },
  },
  localStorage: {
    getInitialValue: (key: string, defaultValue) => {
      const storageItem = window.localStorage.getItem(key);

      if(storageItem === null) {
        window.localStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }

      return JSON.parse(storageItem);
    },
    setValue: (key: string, value) => {
      window.localStorage.setItem(key, JSON.stringify(value));
    },
  },
};

export const storeKey = <T>(
  key: string,
  defaultValue: T,
  dataStore: DataStoreId = 'memory',
): (value: T) => void => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const initialValue: T = dataStores[dataStore].getInitialValue(key, defaultValue) as T;

  const variable = makeVar<T>(initialValue);

  variables[key] = variable as ReactiveVar<unknown>;

  return (value: T) => {
    dataStores[dataStore].setValue(key, value);
    variable(value);
  };
};

export const computeLocalQueryPolicies = (): {
  [key: string]: {
      read: () => unknown;
  };
} => {
  const queryPolicies: { [key: string]: { read: () => unknown } } = {};

  return Object.entries(variables)
    .reduce(
      (queryPolicies, [ key, variable ]) => {
        queryPolicies[key] = {
          read: () => variable(),
        };

        return queryPolicies;
      },
      queryPolicies,
    );
};
