/* Module imports -------------------------------------- */
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { computeLocalQueryPolicies } from './store/store';
import { store as reduxStore } from '../redux/store';
import { selectAuthInfo } from '../redux/slices/authSlice';

/* Type imports ---------------------------------------- */
import type { AuthData } from '../types/AuthData';

/* Type declarations ----------------------------------- */
interface ApolloClientInitArgs {
  backendURL: string;
  graphQLEndpoint: string;
}

/* Apollo Client initialization helper ----------------- */
export const initApolloClient = (
  {
    backendURL,
    graphQLEndpoint,
  }: ApolloClientInitArgs,
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink(
    {
      uri: backendURL + graphQLEndpoint,
    },
  );

  const authLink = setContext(
    (_, { headers }: {headers: { [key: string]: string }}) => {
      // get the authentication token from local storage if it exists
      const authInfo: AuthData = selectAuthInfo(reduxStore.getState());

      if(
        authInfo.authToken === null ||
        authInfo.authToken.length < 1
      ) {
        return {
          headers: {
            ...headers,
          },
        };
      }

      console.log(`[DEBUG] <initApolloClient.authLink> authInfo :`, authInfo);

      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          'X-Openerp-Session-Id': `${authInfo.sessionID}`,
        },
      };
    },
  );

  const apolloCache = new InMemoryCache(
    {
      typePolicies: {
        Query: {
          fields: computeLocalQueryPolicies(),
        },
      },
    },
  );

  const apolloClient = new ApolloClient(
    {
      link: authLink.concat(httpLink),
      cache: apolloCache,
    },
  );

  return apolloClient;
};
