/* Framework imports ----------------------------------- */
import React from 'react';

/* Module imports -------------------------------------- */
import { useNetwork } from '../hooks/useNetwork';
import { Capacitor } from '@capacitor/core';

/* Component imports ----------------------------------- */
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ToastContainer } from 'react-toastify';
import MainLayout from '../layouts/MainLayout/MainLayout';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import CodePushLoader from '../components/CodePushLoader/CodePushLoader';

/* Styling imports ------------------------------------- */
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */

import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';

import '@ionic/react/css/text-transformation.css';

import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'react-toastify/dist/ReactToastify.css';

/* Theme variables */
import '../theme/variables.css';
import '../theme/theme.css';

/* App css */
import './App.css';

/* App component --------------------------------------- */
const App: React.FC = () => {
  const networkStatus = useNetwork();

  return (
    <IonReactRouter>
      <IonApp>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          rtl={false}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {
          Capacitor.getPlatform() !== 'web' &&
          Capacitor.isPluginAvailable('CodePush') &&
            <CodePushLoader />
        }
        {
          networkStatus && networkStatus?.connected ?
            <MainLayout /> :
            <ErrorPage />
        }
      </IonApp>
    </IonReactRouter>
  );
};

/* Export App component -------------------------------- */
export default App;
