/* Framework imports ----------------------------------- */
import { createSlice } from '@reduxjs/toolkit';

/* Type imports ---------------------------------------- */
import type { GeolocationResult } from '../../types/GeolocationResult';
import type { RootState } from '../store';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { GeolocationData } from '../../types/GeolocationData';
import type { GeoActivity } from '../../types/GeoActivity';

/* Geolocation slice initial values -------------------- */
const initialValues: GeolocationResult = {
  enabled: false,
  ready: false,
  isMoving: false,
  location: undefined,
  activity: undefined,
};

/* Geolocation data Redux slice ------------------------ */
const geolocationSlice = createSlice(
  {
    name: 'geolocation',
    initialState: initialValues,
    reducers: {
      setGeolocationReady: (
        state,
        { payload }: PayloadAction<boolean>,
      ) => {
        state.ready = payload;
      },
      setGeolocationEnabled: (
        state,
        { payload }: PayloadAction<boolean>,
      ) => {
        state.enabled = payload;
      },
      setGeolocationLocation: (
        state,
        { payload }: PayloadAction<GeolocationData | undefined>,
      ) => {
        state.location = payload;
      },
      setGeolocationActivity: (
        state,
        { payload }: PayloadAction<GeoActivity | undefined>,
      ) => {
        state.activity = payload;
      },
      setGeolocationIsMoving: (
        state,
        { payload }: PayloadAction<boolean>,
      ) => {
        state.isMoving = payload;
      },
      resetAuthInfo: (state) => {
        state.enabled = false;
        state.ready = false;
        state.isMoving = false;
        state.location = undefined;
        state.activity = undefined;
      },
    },
  },
);

/* Export slice components ----------------------------- */
export const {
  setGeolocationReady,
  setGeolocationEnabled,
  setGeolocationLocation,
  setGeolocationActivity,
  setGeolocationIsMoving,
  resetAuthInfo,
} = geolocationSlice.actions;

export default geolocationSlice.reducer;

export const selectGeolocationData = (state: RootState): GeolocationResult => {
  return state.geolocation;
};
