/* Framework imports ----------------------------------- */
import {
  IonItem,
  IonLabel,
  IonNote,
} from '@ionic/react';
import React from 'react';

/* Configuration imports ------------------------------- */
import { BACKGROUND_GEOLOC_CONFIG } from '../../config/backgroundGeolocation.config';

/* Type imports ---------------------------------------- */
import type { GeolocationResult } from '../../types/GeolocationResult';

/* Styling --------------------------------------------- */
const geolocationStatusStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',

  textAlign: 'center',

  padding: '.5rem',
};

/* GeolocDataDisplay component prop types -------------- */
interface GeolocDataDisplayProps extends GeolocationResult {}

/* GeolocDataDisplay component ------------------------- */
const GeolocDataDisplay: React.FC<GeolocDataDisplayProps> = (
  {
    ready,
    enabled,
    isMoving,
    location,
    activity,
  },
) => {
  if(ready === undefined || !ready) {
    return (
      <IonItem style={{ color: '#FF0000' }}>
        <div style={geolocationStatusStyle}>
          Geolocation tracking service is not ready.
        </div>
      </IonItem>
    );
  }

  if(enabled === undefined || !enabled) {
    return (
      <IonItem style={{ color: '##ffcc00' }}>
        <div style={geolocationStatusStyle}>
          Geolocation tracking service is not enabled.
        </div>
      </IonItem>
    );
  }

  return (
    <>
      <IonItem style={{ color: '#339900' }}>
        <div style={geolocationStatusStyle}>
          Geolocation tracking service is enabled.
        </div>
      </IonItem>
      <IonItem>
        <IonLabel>
          Latitude
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {location?.coords?.latitude ?? 'N/A'}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonLabel>
          Longitude
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {location?.coords?.longitude ?? 'N/A'}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonLabel>
          isMoving
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {isMoving !== undefined && isMoving ? 'true' : 'false'}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonLabel>
          Activity type
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {`${activity?.activity}`}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonLabel>
          Activity confidence
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {`${activity?.confidence}`}
        </IonNote>
      </IonItem>
      {
        BACKGROUND_GEOLOC_CONFIG.debug === true ?
          <>
            <IonItem>
              <IonLabel>
                Debug mode
              </IonLabel>
              <IonNote
                slot="end"
                color="primary"
              >
                {`${BACKGROUND_GEOLOC_CONFIG.debug}`}
              </IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>
                Backend URL
              </IonLabel>
              <IonNote
                slot="end"
                color="primary"
              >
                {BACKGROUND_GEOLOC_CONFIG.url}
              </IonNote>
            </IonItem>
          </> :
          <IonItem>
            <IonLabel>
              Debug mode
            </IonLabel>
            <IonNote
              slot="end"
              color="primary"
            >
              {`${BACKGROUND_GEOLOC_CONFIG.debug}`}
            </IonNote>
          </IonItem>
      }
    </>
  );
};

/* Export GeolocDataDisplay component ------------------ */
export default GeolocDataDisplay;
