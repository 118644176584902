/* Framework imports ----------------------------------- */
import React from 'react';
import { useParams } from 'react-router-dom';

/* Module imports -------------------------------------- */
import { useRideQuery } from '../../apollo/queries';
import { useGeolocationData } from '../../redux/hooks';
import {
  getRideStatusIcon,
  getRideTypeIcon,
} from '../../helpers/iconHelpers';
import { getStringLines } from '../../helpers/processStrings';

/* Component imports ----------------------------------- */
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonSpinner,
} from '@ionic/react';
import PageLayout from '../../layouts/PageLayout/PageLayout';
import GeolocDataDisplay from '../../components/GeolocDataDisplay/GeolocDataDisplay';
import RidePageFAB from './RidePageFAB';
import {
  formatDateStrForMobile,
  formatDateStr,
} from '../../helpers/dateHelpers';
import RideConfirmationQR from './RideConfirmationQR';

/* Style imports --------------------------------------- */

/* Type imports ---------------------------------------- */

/* Styling --------------------------------------------- */
const missionNameDivStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',

  fontSize: '1.2rem',
  textAlign: 'center',

  padding: '.5rem',
};

/* RidePage component prop types ----------------------- */
interface RidePageProps {}

/* RidePage component ---------------------------------- */
const RidePage: React.FC<RidePageProps> = () => {
  const { rideID } = useParams<{ rideID: string }>();

  const geolocationData = useGeolocationData();

  const {
    loading: loadingRide,
    // error: errorRide,
    data: dataRide,
    refetch: refetchRide,
  } = useRideQuery(
    {
      variables: {
        id: parseInt(rideID),
      },
      skip: rideID === undefined ||
        rideID === null ||
        rideID.length < 1,
    },
  );

  const ride = dataRide?.getCommunicorRide;

  React.useEffect(
    () => {
      console.log(`[DEBUG] <RidePage> Expected start date (raw) :`, ride?.startDate);
      console.log(`[DEBUG] <RidePage> Driver's start date (raw) :`, ride?.driverStartDate);
    },
    [
      ride?.startDate,
      ride?.driverStartDate,
    ],
  );

  return (
    <PageLayout
      menu
      backButtonLink="/rides"
      title="Ride details"
      onRefresh={
        async () => {
          await refetchRide(
            {
              id: parseInt(rideID),
            },
          );
        }
      }
      footer={
        ride !== null &&
        ride !== undefined &&
          <RidePageFAB
            ride={ride}
            refetchRide={refetchRide}
            disabled={loadingRide}
          />
      }
    >
      {
        loadingRide === true ?
          <div style={{ textAlign: 'center' }}>
            <IonSpinner />
            <h6>
              {`Loading rides...`}
            </h6>
          </div> :
          ride === null ||
          ride === undefined ?
            <div style={{ textAlign: 'center' }}>
              <h6>
                {`Error : ride not found`}
              </h6>
            </div> :
            <IonList>
              {
                ride.missionId?.displayName !== null &&
                ride.missionId?.displayName !== undefined &&
                  <IonItem>
                    <div style={missionNameDivStyle}>
                      {ride.missionId?.displayName}
                    </div>
                  </IonItem>
              }
              {
                ride.passengerId?.displayName !== undefined &&
                ride.passengerId?.displayName !== null &&
                  <IonItem>
                    <IonLabel>
                      Passenger(s)
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {ride.passengerId?.displayName}
                    </IonNote>
                  </IonItem>
              }
              {
                ride.passengerId?.isPassengersGroup === true &&
                ride.passengerId?.groupName !== undefined &&
                ride.passengerId?.groupName !== null &&
                  <IonItem>
                    <IonLabel>
                      Passenger group
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {ride.passengerId.groupName}
                    </IonNote>
                  </IonItem>
              }
              {
                ride.passengerId?.isPassengersGroup === true &&
                ride.passengerId?.groupSize !== undefined &&
                ride.passengerId?.groupSize !== null &&
                  <IonItem>
                    <IonLabel>
                      Group size
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {`${ride.passengerId.groupSize} passenger${ride.passengerId?.groupSize === 1 ? '' : 's'}`}
                    </IonNote>
                  </IonItem>
              }
              {
                ride.state.value !== null &&
                ride.state.value !== undefined &&
                  <IonItem>
                    <IonLabel>
                      Status
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {ride.state.value}
                    </IonNote>
                    {
                      ride.state.key !== null &&
                      ride.state.key !== undefined &&
                        <IonIcon
                          color={
                            ride.state?.key === 'waiting' ?
                              'primary' :
                              ride.state?.key === 'wip' ?
                                'warning' :
                                'success'
                          }
                          icon={getRideStatusIcon(ride.state.key)}
                          slot="end"
                        />
                    }
                  </IonItem>
              }
              {
                ride.type.value !== null &&
                ride.type.value !== undefined &&
                  <IonItem>
                    <IonLabel>
                      Type
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {ride.type.value}
                    </IonNote>
                    {
                      ride.type.key !== null &&
                      ride.type.key !== undefined &&
                        <IonIcon
                          icon={getRideTypeIcon(ride.type.key)}
                          slot="end"
                          color="primary"
                        />
                    }
                  </IonItem>
              }
              {
                ride.startDate !== undefined &&
                ride.startDate !== null &&
                  <IonItem>
                    <IonLabel>
                      Expected start
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {
                        formatDateStr(
                          formatDateStrForMobile(ride.startDate),
                          {
                            month: '2-digit',
                          },
                        )
                      }
                    </IonNote>
                  </IonItem>
              }
              {
                ride.startPlace !== null &&
                ride.startPlace !== undefined &&
                  <IonItem>
                    <IonLabel>
                      Expected picked up
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {ride.startPlace}
                    </IonNote>
                  </IonItem>
              }
              {
                ride.state.key !== undefined &&
                ride.state.key !== null &&
                [ 'wip', 'completed', 'confirmed' ].includes(ride.state.key) &&
                ride.driverStartDate !== undefined &&
                ride.driverStartDate !== null &&
                  <IonItem>
                    <IonLabel>
                      Started at
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {
                        formatDateStr(
                          formatDateStrForMobile(ride.driverStartDate),
                          {
                            month: '2-digit',
                          },
                        )
                      }
                    </IonNote>
                  </IonItem>
              }
              {
                ride.state.key !== undefined &&
                ride.state.key !== null &&
                [ 'wip', 'completed', 'confirmed' ].includes(ride.state.key) &&
                ride.driverStartPlace !== null &&
                ride.driverStartPlace !== undefined &&
                  <IonItem>
                    <IonLabel>
                      Picked up at
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {ride.driverStartPlace}
                    </IonNote>
                  </IonItem>
              }
              {
                ride.endDate !== undefined &&
                ride.endDate !== null &&
                  <IonItem>
                    <IonLabel>
                      Expected end at
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {
                        formatDateStr(
                          formatDateStrForMobile(ride.endDate),
                          {
                            month: '2-digit',
                          },
                        )
                      }
                    </IonNote>
                  </IonItem>
              }
              {
                ride.endPlace !== null &&
                ride.endPlace !== undefined &&
                  <IonItem>
                    <IonLabel>
                      Expected drop off
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {ride.endPlace}
                    </IonNote>
                  </IonItem>
              }
              {
                ride.state.key !== undefined &&
                ride.state.key !== null &&
                [ 'completed', 'confirmed' ].includes(ride.state.key) &&
                ride.driverEndDate !== undefined &&
                ride.driverEndDate !== null &&
                  <IonItem>
                    <IonLabel>
                      Completed at
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {
                        formatDateStr(
                          formatDateStrForMobile(ride.driverEndDate),
                          {
                            month: '2-digit',
                          },
                        )
                      }
                    </IonNote>
                  </IonItem>
              }
              {
                ride.state.key !== undefined &&
                ride.state.key !== null &&
                [ 'completed', 'confirmed' ].includes(ride.state.key) &&
                ride.driverEndPlace !== null &&
                ride.driverEndPlace !== undefined &&
                  <IonItem>
                    <IonLabel>
                      Dropped off at
                    </IonLabel>
                    <IonNote
                      slot="end"
                      color="primary"
                    >
                      {ride.driverEndPlace}
                    </IonNote>
                  </IonItem>
              }
              {
                ride.description !== undefined &&
                ride.description !== null &&
                ride.description.length > 0 &&
                  <IonItem>
                    <IonLabel style={{ whiteSpace: 'normal' }}>
                      Description :
                      <br />
                      <br />
                      {
                        getStringLines(ride.description)
                          .map(
                            (pLine: string, pIndex: number, pArray: string[]): React.ReactNode => {
                              return (
                                <>
                                  {pLine}
                                  {
                                    pIndex !== pArray.length &&
                                      <br />
                                  }
                                </>
                              );
                            },
                          )
                      }
                    </IonLabel>
                  </IonItem>
              }
              {
                ride.state.key === 'complete' &&
                  <RideConfirmationQR ride={ride} />
              }
              {
                ride.state.key === 'wip' &&
                  <GeolocDataDisplay
                    ready={geolocationData?.ready ?? false}
                    enabled={geolocationData?.enabled ?? false}
                    isMoving={geolocationData?.isMoving ?? false}
                    location={geolocationData?.location}
                    activity={geolocationData?.activity}
                  />
              }
            </IonList>
      }
    </PageLayout>
  );
};

/* Export RidePage component --------------------------- */
export default RidePage;
