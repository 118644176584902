/* Framework imports ----------------------------------- */
import React from 'react';
import { Link } from 'react-router-dom';

/* Module imports -------------------------------------- */
import {
  formatDateStrForMobile,
  formatDate,
} from '../../helpers/dateHelpers';
import {
  getRideStatusIcon,
  getRideTypeIcon,
} from '../../helpers/iconHelpers';

/* Component imports ----------------------------------- */
import { IonIcon } from '@ionic/react';

/* Style imports --------------------------------------- */

/* Type imports ---------------------------------------- */
import type { RideFragment } from '../../apollo/queries/types';

/* Styling ---------------------------------------------- */
const rideListItemStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'stretch',

  width: '100%',

  textDecoration: 'none !important',

  borderStyle: 'none',
  borderRadius: '12px',
  borderColor: '#555555',
  borderWidth: '2px',

  marginTop: '10px',
  marginBottom: '10px',
  padding: '.15rem',

  color: '#FFFFFF',
  backgroundColor: '#222222',
  boxShadow: '0px 0px 6px 0px rgba(153,153,153,0.75)',
};

const rideListItemStartIconStyle: React.CSSProperties = {
  margin: '0 10px 0 5px',

  width: '24px',
  maxWidth: '24px',
  height: '24px',
};

const rideListItemEndIconStyle: React.CSSProperties = {
  margin: '0 5px 0 10px',

  width: '30px',
  maxWidth: '30px',
  height: '30px',
};

const rideListItemContentsStyle: React.CSSProperties = {
  flex: 2,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'stretch',
};

const rideListItemPassengerDiv: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  paddingTop: '.5rem',

  width: '100%',
};
const rideListItemPassengerName: React.CSSProperties = {
  fontSize: '1.1rem',
};

const rideListItemMissionTitle: React.CSSProperties = {
  textAlign: 'center',

  margin: '10px 10px',
};

const rideListItemDivider: React.CSSProperties = {
  width: '90%',

  margin: '.25rem 0',

  border: '0',
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderBottomColor: 'rgba(153,153,153,0.75)',
};

const rideListItemStartPlace: React.CSSProperties = {
  fontSize: '.9rem',
  color: 'var(--ion-color-medium-shade)',

  textAlign: 'right',

  margin: '5px',
};

/* RideListItem component prop types -------------------- */
interface RideListItemProps {
  ride: RideFragment;
}

/* RideListItem component ------------------------------- */
const RideListItem: React.FC<RideListItemProps> = ({ ride }) => {
  console.log(`[DEBUG] <RideListItem> (${ride.id}) ride.driverStartDate :`, ride.driverStartDate);
  const lFormattedStartDateStr: string = formatDate(new Date(formatDateStrForMobile(ride.driverStartDate!)));

  return (
    <Link
      to={`/rides/${ride.id}`}
      style={{ textDecoration: 'none' }}
    >
      <div style={rideListItemStyle}>
        <div style={rideListItemContentsStyle}>
          <div style={rideListItemPassengerDiv}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon
                icon={getRideTypeIcon(ride.type.key)}
                color="primary"
                style={rideListItemStartIconStyle}
              />
              <span style={rideListItemPassengerName}>
                {`${ride.passengerId?.name}`}
              </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ margin: 0 }}>
                {lFormattedStartDateStr}
              </span>
              <IonIcon
                color={
                  ride.state?.key === 'waiting' ?
                    'primary' :
                    ride.state?.key === 'wip' ?
                      'warning' :
                      'success'
                }
                style={rideListItemEndIconStyle}
                icon={getRideStatusIcon(ride.state.key)}
              />
            </div>
          </div>
          {
            ride.missionId?.displayName !== null &&
            ride.missionId?.displayName !== undefined &&
            ride.missionId.displayName.length > 0 &&
              <h4 style={rideListItemMissionTitle}>
                {ride.missionId.displayName}
              </h4>
          }
          {
            ride.driverStartPlace !== null &&
            ride.driverStartPlace !== undefined &&
            ride.driverStartPlace.length > 1 &&
              <>
                <hr
                  className="solid"
                  style={rideListItemDivider}
                />
                <span style={rideListItemStartPlace}>
                  {ride.driverStartPlace}
                </span>
              </>
          }
        </div>
      </div>
    </Link>
  );
};

/* Export RideListItem component ------------------------ */
export default RideListItem;
