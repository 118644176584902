/* Framework imports ----------------------------------- */
import React from 'react';

/* Component imports ----------------------------------- */
import {
  IonContent,
  IonPage,
} from '@ionic/react';

/* Style imports --------------------------------------- */
import './ErrorPage.css';

/* ErrorPage component --------------------------------- */
const ErrorPage: React.FC = () => {

  return (
    <IonPage>
      <IonContent fullscreen>
        <div>
          <div
            style={
              {
                height: '90px',
                position: 'relative',
                backgroundColor: '#f3f4f5',
                paddingTop: 0,
              }
            }
          >
            <div className="login-smooth">
              <img
                // TODO : Change logo if light mode
                src="/assets/logo/Communicor_Logo_C_only_W&B.png"
                alt="App logo"
                style={{ maxWidth: '50vw' }}
              />
            </div>
          </div>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#f3f4f5"
              fillOpacity="1"
              d="M0,192L30,186.7C60,181,120,171,180,149.3C240,128,300,96,360,122.7C420,149,480,235,540,245.3C600,256,660,192,720,165.3C780,139,840,149,900,165.3C960,181,1020,203,1080,202.7C1140,203,1200,181,1260,144C1320,107,1380,53,1410,26.7L1440,0L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
            />
          </svg> */}
          <div
            className="login-header"
          >
            Oups !
          </div>
          <div
            style={
              {
                fontSize: '25px',
                paddingTop: 5,
              }
            }
            className="login-subheader"
          >
            📡
          </div>
          <div
            className="login-subheader"
          >
            Vous n'êtes pas connecté à internet
          </div>
          <p
            style={
              {
                padding: 30,
                fontWeight: 'bold',
                textAlign: 'center',
              }
            }
          >
            Il est nécessaire de posséder une connexion internet pour accéder à l'application chauffeur Communicor.
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

/* Export ErrorPage component -------------------------- */
export default ErrorPage;
