/* Module imports -------------------------------------- */
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
import { BACKGROUND_GEOLOC_CONFIG } from '../config/backgroundGeolocation.config';

/* Type imports ---------------------------------------- */
import type {
  Config,
  State,
  LocationError,
  MotionChangeEvent,
  MotionActivityEvent,
  ProviderChangeEvent,
  ConnectivityChangeEvent,
  AuthorizationEvent,
  HttpEvent,
} from '@transistorsoft/capacitor-background-geolocation';
import type { GeolocationData } from '../types/GeolocationData';
import type { GeoActivity } from '../types/GeoActivity';
import { GeoActivityType } from '../types/GeoActivityType';
import { AuthData } from '../types/AuthData';

/* Internal variables ---------------------------------- */
let setGeolocationReady: ((pReadyOrNot: boolean) => void) | null = null;
let setGeolocationEnabled: ((pEnabledOrNot: boolean) => void) | null = null;
let setGeolocationLocation: ((pGeolocationData: GeolocationData) => void) | null = null;
let setGeolocationActivity: ((pActivity: GeoActivity) => void) | null = null;
let setGeolocationIsMoving: ((pIsMoving: boolean) => void) | null = null;

/* Geolocation helper functions ------------------------ */
const locationErrorToStr = (pLocationError: LocationError) => {
  /*
   * | Code  | Error                       |
   * |-------|-----------------------------|
   * | 0     | Location unknown            |
   * | 1     | Location permission denied  |
   * | 2     | Network error               |
   * | 408   | Location timeout            |
   * | 499   | Location request cancelled  |
   */
  switch(pLocationError) {
    case 0:
      return 'Location unknown';
    case 1:
      return 'Location permission denied';
    case 2:
      return 'Network error';
    case 408:
      return 'Location request cancelled';
    case 499:
      return 'Location request cancelled';
    default:
      return `Unknown error code ${pLocationError as number}`;
  }
};

const initGeolocationEventHandlers = (): void => {
  // See the API docs for a list of all available events -- there are a total of 13 events.

  // This handler fires whenever bgGeo receives a location update.
  BackgroundGeolocation.onLocation(
    (pLocationData: GeolocationData) => {
      // console.log('[DEBUG] <onLocation> Got location :', (pLocationData));

      setGeolocationLocation && setGeolocationLocation(pLocationData);
    },
    (pError: LocationError) => { // <-- Location errors
      const lErrorStr: string = locationErrorToStr(pError);
      console.error('[ERROR] <onLocation> Location error :', lErrorStr);
    },
  );

  // This handler fires when movement states changes (stationary->moving; moving->stationary)
  BackgroundGeolocation.onMotionChange(
    (pMotionChangeEvent: MotionChangeEvent) => {
      console.log('[DEBUG] <onMotionChange> Motion detected :', JSON.stringify(pMotionChangeEvent));

      setGeolocationIsMoving && setGeolocationIsMoving(pMotionChangeEvent.isMoving);
    },
  );

  // This event fires when a change in motion activity is detected
  BackgroundGeolocation.onActivityChange(
    (pMotionActivityEvent: MotionActivityEvent) => {
      console.log('[DEBUG] <onActivityChange> Activity change detected :', JSON.stringify(pMotionActivityEvent));

      let lActivity: GeoActivityType = GeoActivityType.UNKNOWN;

      if(
        Object.values(GeoActivityType)
          .includes(pMotionActivityEvent.activity as GeoActivityType)
      ) {
        lActivity = pMotionActivityEvent.activity as GeoActivityType;
      }

      setGeolocationActivity && setGeolocationActivity(
        {
          confidence: pMotionActivityEvent.confidence,
          activity: lActivity,
        },
      );
    },
  );

  // This event fires when the user toggles location-services authorization
  BackgroundGeolocation.onProviderChange(
    (pProviderChangeEvent: ProviderChangeEvent) => {
      console.log('[DEBUG] <onProviderChange> Provider change detected :', JSON.stringify(pProviderChangeEvent));
    },
  );

  BackgroundGeolocation.onPowerSaveChange(
    (pIsPowerSaveEnabled: boolean) => {
      console.log('[DEBUG] <onConnectivityChange> Connectivity change detected :', JSON.stringify(pIsPowerSaveEnabled));
    },
  );

  BackgroundGeolocation.onConnectivityChange(
    (pConnectivityChangeEvent: ConnectivityChangeEvent) => {
      console.log('[DEBUG] <onConnectivityChange> Connectivity change detected :', JSON.stringify(pConnectivityChangeEvent));
    },
  );

  BackgroundGeolocation.onAuthorization(
    (pAuthorizationEvent: AuthorizationEvent) => {
      console.log('[DEBUG] <onAuthorization> Connectivity change detected :', JSON.stringify(pAuthorizationEvent));
    },
  );

  BackgroundGeolocation.onHttp(
    (pHTTPResponse: HttpEvent) => {
      console.log(`[DEBUG] <onHttp> HTTP event received :`, JSON.stringify(pHTTPResponse));
      console.log(`        success      :`, JSON.stringify(pHTTPResponse.success));
      console.log(`        status       :`, JSON.stringify(pHTTPResponse.status));
      console.log(`        responseText :`, JSON.stringify(pHTTPResponse.responseText));
    },
  );
};

/* Geolocation initialization functions ---------------- */
/// WARNING: DO NOT Use ionViewWillEnter to configure the SDK -- use ngAfterContentInit. ionViewWillEnter only executes when the
/// app is brought to the foreground.  It will NOT execute when the app is launched in the background,
/// as the BackgroundGeolocation SDK will often do.
///
const initBackgroundGeolocation = async (
  pSetGeolocationReady: (pReadyOrNot: boolean) => void,
  pSetGeolocationEnabled: (pEnabledOrNot: boolean) => void,
  pSetGeolocationLocation: (pCoords: GeolocationData) => void,
  pSetGeolocationActivity: (pActivity: GeoActivity) => void,
  pSetGeolocationIsMoving: (pIsMoving: boolean) => void,
): Promise<State> => {
  setGeolocationReady = pSetGeolocationReady;
  setGeolocationEnabled = pSetGeolocationEnabled;
  setGeolocationLocation = pSetGeolocationLocation;
  setGeolocationActivity = pSetGeolocationActivity;
  setGeolocationIsMoving = pSetGeolocationIsMoving;

  try {
    ////
    // 1.  Wire up event-listeners
    //

    initGeolocationEventHandlers();

    ////
    // 2.  Execute #ready method (required)
    //
    const lBackgroundGeolocationState: State = await BackgroundGeolocation.ready(BACKGROUND_GEOLOC_CONFIG);
    console.log('[DEBUG] <initBackgroundGeolocation> Initialized the BackgroundGeolocation tracker :', lBackgroundGeolocationState);

    setGeolocationEnabled(lBackgroundGeolocationState.enabled);
    setGeolocationReady(true);

    return lBackgroundGeolocationState;

    ////
    // 3. Start tracking when ready!
    //
  } catch(pException) {
    console.error('[ERROR] <initBackgroundGeolocation> Failed to initialize the BackgroundGeolocation tracker :', pException);

    setGeolocationReady(false);

    throw pException;
  }
};

const startGeolocationTracker = async (pAuthData: AuthData): Promise<State> => {
  try {
    if(
      pAuthData.authToken !== null &&
      pAuthData.authUID !== null
    ) {
      const lNewConfig: Config = {
        ...BACKGROUND_GEOLOC_CONFIG,
        params: {
          uid: pAuthData.authUID,
        },
        headers: {
          ...BACKGROUND_GEOLOC_CONFIG.headers,
          'X-Openerp-Session-Id': pAuthData.sessionID ?? '',
        // eslint-disable-next-line @typescript-eslint/ban-types
        } as Object,
      };

      // const lNewState = await BackgroundGeolocation.reset(lNewConfig);
      await BackgroundGeolocation.setConfig(lNewConfig);

      const lState: State = await BackgroundGeolocation.start();
      setGeolocationEnabled && setGeolocationEnabled(true);

      console.log('[INFO ] <BackgroundGeolocationManager.startGeolocationTracker> Started geolocation tracker :', lState);

      return lState;
    }

    console.error(`[ERROR] <BackgroundGeolocationManager.startGeolocationTracker> Can't start the geolocation tracker, the user is not logged in !`);
    throw new Error(`Can't start the geolocation tracker, the user is not logged in !`);
  } catch(pException) {
    setGeolocationEnabled && setGeolocationEnabled(false);

    console.error(`[ERROR] <BackgroundGeolocationManager.startGeolocationTracker> Failed to start the geolocation tracker :`, pException);
    throw pException;
  }
};

const stopGeolocationTracker = async (): Promise<State> => {
  try {
    await BackgroundGeolocation.setConfig(BACKGROUND_GEOLOC_CONFIG);
    const lState: State = await BackgroundGeolocation.stop();
    setGeolocationEnabled && setGeolocationEnabled(false);

    console.log('[INFO ] <BackgroundGeolocationManager.stopGeolocationTracker> Stopped geolocation tracker :', lState);

    return lState;
  } catch(pException) {
    setGeolocationEnabled && setGeolocationEnabled(true);

    console.error(`[ERROR] <BackgroundGeolocationManager.stopGeolocationTracker> Failed to stop the geolocation tracker :`, pException);
    throw pException;
  }
};

/* Export BackgroundGeolocationManager ----------------- */
export default {
  initBackgroundGeolocation,
  startGeolocationTracker,
  stopGeolocationTracker,
};
