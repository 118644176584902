/* Framework imports ----------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  App,
  AppState,
} from '@capacitor/app';
import {
  codePush,
  InstallMode,
/* eslint-disable import/no-unresolved */
} from 'capacitor-codepush'; /* Not resolved because this package comes directly from git */

/* Component imports ----------------------------------- */
import { IonLoading } from '@ionic/react';

/* Type imports ---------------------------------------- */
import { SyncStatus } from 'capacitor-codepush/dist/esm/syncStatus';
import type { SyncOptions } from 'capacitor-codepush/dist/esm/syncOptions';
import type { DownloadProgress } from 'capacitor-codepush/dist/esm/package';
import type {
  ErrorCallback,
  SuccessCallback,
} from 'capacitor-codepush/dist/esm/callbackUtil';
/* eslint-enable import/no-unresolved */
import type { PluginListenerHandle } from '@capacitor/core';

/* Style imports --------------------------------------- */
import './CodePushLoader.css';

/* Helper functions ------------------------------------ */
const buildProgressStr: SuccessCallback<DownloadProgress> = (pProgress?: DownloadProgress) => {
  if(pProgress !== undefined) {
    const lProgressPctg = pProgress.receivedBytes / pProgress?.totalBytes;
    const lProgressStr: string = `${pProgress?.receivedBytes} of ${pProgress?.totalBytes} received. (${lProgressPctg})`;

    // console.log(`[DEBUG] <CodePushLoader.buildProgressStr> ${lProgressStr}`);

    // return lProgressStr;
  }
};

const syncStatusToStr = (pStatus: SyncStatus): string => {
  switch(pStatus) {
    case SyncStatus.UP_TO_DATE:
      return "L'application est à jour";
    case SyncStatus.UPDATE_INSTALLED:
      return 'Mise à jour installée';
    case SyncStatus.UPDATE_IGNORED:
      return 'Mise à jour ignorée';
    case SyncStatus.ERROR:
      return 'Erreur pendant la mise à jour';
    case SyncStatus.IN_PROGRESS:
      return 'Mise à jour en cours';
    case SyncStatus.CHECKING_FOR_UPDATE:
      return 'Recherche de mise à jour';
    case SyncStatus.AWAITING_USER_ACTION:
      return "En attente d'une action de l'utilisateur";
    case SyncStatus.DOWNLOADING_PACKAGE:
      return 'Téléchargement de la mise à jour';
    case SyncStatus.INSTALLING_UPDATE:
      return 'Installation de la mise à jour';
    default:
      return 'État de mise à jour inconnu';
  }
};

/* CodePushLoader component -------------------------------- */
const CodePushLoader: React.FC = () => {
  // const [ updating, setUpdating ] = useState<boolean>(false);
  const [ codePushSyncState, setCodePushSyncState ] = useState<SyncStatus>(SyncStatus.CHECKING_FOR_UPDATE);
  const [ appStatusListenerHandle, setAppStatusListenerHandle ] = useState<PluginListenerHandle>();

  const buildLoadingStr = () => {
    if(codePushSyncState === undefined) {
      return 'Chargement...';
    } else {
      return `${syncStatusToStr(codePushSyncState)}...`;
    }

    /* TODO: If iOS, return 'Chargement...' because of the App Store guidelines */
  };

  const isLoadingUpdate = (): boolean => {
    let lLoading: boolean = false;

    switch(codePushSyncState) {
      /* CHECKING_FOR_UPDATE commented to avoid rendering the Loader each time the app resumes */
      // case SyncStatus.CHECKING_FOR_UPDATE:
      case SyncStatus.IN_PROGRESS:
      case SyncStatus.AWAITING_USER_ACTION:
      case SyncStatus.DOWNLOADING_PACKAGE:
      case SyncStatus.INSTALLING_UPDATE:
        lLoading = lLoading || true;
    }

    return lLoading;
  };

  useEffect(
    () => {
      let lCompIsMounted = true;

      const syncStatusChanged: SuccessCallback<SyncStatus> = (
        pSyncState?: SyncStatus,
      ): void => {
        if(pSyncState !== undefined) {
          lCompIsMounted && setCodePushSyncState(pSyncState);

          switch(pSyncState) {
            case SyncStatus.UP_TO_DATE:
              console.log('[INFO ] <CodePushLoader.syncStatusChanged> Up-to-date.');
              break;
            case SyncStatus.UPDATE_INSTALLED:
              console.log('[INFO ] <CodePushLoader.syncStatusChanged> Update installed.');
              break;
            case SyncStatus.UPDATE_IGNORED:
              console.warn('[WARN ] <CodePushLoader.syncStatusChanged> Update ignored.');
              break;
            case SyncStatus.ERROR:
              console.error('[ERROR] <CodePushLoader.syncStatusChanged> Error occurred.');
              break;
            case SyncStatus.IN_PROGRESS:
              console.log('[INFO ] <CodePushLoader.syncStatusChanged> Update in progress.');
              break;
            case SyncStatus.CHECKING_FOR_UPDATE:
              console.log('[INFO ] <CodePushLoader.syncStatusChanged> Checking for updates.');
              break;
            case SyncStatus.AWAITING_USER_ACTION:
              console.log('[INFO ] <CodePushLoader.syncStatusChanged> Awaiting user interaction.');
              break;
            case SyncStatus.DOWNLOADING_PACKAGE:
              console.log('[INFO ] <CodePushLoader.syncStatusChanged> Downloading package.');
              break;
            case SyncStatus.INSTALLING_UPDATE:
              console.log('[INFO ] <CodePushLoader.syncStatusChanged> Installing update.');
              break;
            default:
              console.error(`[ERROR] <CodePushLoader.syncStatusChanged> Unknown sync status :`, pSyncState);
          }
        } else {
          console.error(`[ERROR] <CodePushLoader.syncStatusChanged> Sync status is undefined !`);
        }
      };

      const syncError: ErrorCallback = (pError?: Error | null) => {
        console.error(`[ERROR] <CodePushLoader.syncError> Error :`, pError);
      };

      const lSyncOptions: SyncOptions = {
        updateDialog: false,
        mandatoryInstallMode: InstallMode.IMMEDIATE,
        installMode: InstallMode.IMMEDIATE,
        minimumBackgroundDuration: 2 * 60 * 60, /* 2 hours */
        // deploymentKey: '????',
        onSyncStatusChanged: syncStatusChanged,
        onSyncError: syncError,
      };

      App.addListener(
        'appStateChange',
        (pState: AppState) => {
          if(pState.isActive) {
            // lCompIsMounted && setUpdating(true);

            codePush.sync(
              lSyncOptions,
              buildProgressStr,
            )
              .then(
                (pResultingSyncStatus: SyncStatus) => {
                  // console.log(`[DEBUG] <CodePushLoader.sync> Sync returned SyncStatus :`, pResultingSyncStatus, `(${syncStatusToStr(pResultingSyncStatus)})`);
                },
              )
              .catch(
                (pException) => {
                  console.error(`[ERROR] <CodePushLoader.sync> CodePush sync failed :`, pException);
                },
              )
              .finally(
                () => {
                  // lCompIsMounted && setUpdating(false);
                },
              );
          }
        },
      )
        .then(
          (pListenerHandle: PluginListenerHandle) => {
            // console.log(`[DEBUG] <CodePushLoader> addListener on 'appStateChange' event succeeded :`, pListenerHandle);
            lCompIsMounted && setAppStatusListenerHandle(pListenerHandle);
          },
        )
        .catch(
          (pException) => {
            console.error(`[ERROR] <CodePushLoader> addListener on 'appStateChange' event failed :`, pException);
          },
        );

      return () => {
        lCompIsMounted = false;

        if(appStatusListenerHandle !== undefined) {
          appStatusListenerHandle.remove()
            .then(
              () => {
                // console.log(`[DEBUG] <CodePushLoader> appStatusListenerHandle.remove on 'appStateChange' event succeeded.`);
                setAppStatusListenerHandle(undefined); /* .finally instead of .then ? */
              },
            )
            .catch(
              (pException) => {
                console.error(`[ERROR] <CodePushLoader> appStatusListenerHandle.remove on 'appStateChange' event failed :`, pException);
              },
            );
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], /* Empty deps array, this will run only on the first render */
  );

  return (
    <IonLoading
      cssClass="code-push-loader"
      isOpen={isLoadingUpdate()}
      message={buildLoadingStr()}
    />
  );
};

/* Export CodePushLoader component ------------------------- */
export default CodePushLoader;
