/* Module imports -------------------------------------- */
import {
  carSportOutline,
  checkmarkCircleOutline,
  hourglassOutline,
  timeOutline,
  todayOutline,
  airplaneOutline,
} from 'ionicons/icons';

/* Icon helper functions ------------------------------- */
export const getRideStatusIcon = (state: string | null | undefined): string => {
  switch(state) {
    case 'complete':
      return timeOutline;
    case 'wip':
      return carSportOutline;
    case 'confirmed':
      return checkmarkCircleOutline;
    case 'waiting':
      return hourglassOutline;
    default:
      return timeOutline;
  }
};

export const getRideTypeIcon = (type: string | null | undefined): string => {
  switch(type) {
    case 'daily':
      return todayOutline;
    case 'airport':
      return airplaneOutline;
    default:
      return carSportOutline;
  }
};
