/* Framework imports ----------------------------------- */
import React from 'react';

/* Module imports -------------------------------------- */
import { menuController } from '@ionic/core';

/* Component imports ----------------------------------- */
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonMenuToggle,
} from '@ionic/react';
import {
  logOutOutline,
  logOutSharp,
  calendarOutline,
  calendarSharp,
  navigateOutline,
  navigateSharp,
} from 'ionicons/icons';

/* Styling imports ------------------------------------- */
import './SideMenu.css';

/* Type imports ---------------------------------------- */
import type { AuthData } from '../../types/AuthData';

/* SideMenu component prop types ----------------------- */
interface SideMenuProps {
  afterLogout?: (pAuthData: AuthData) => Promise<void>;
}

/* SideMenu component ---------------------------------- */
const SideMenu: React.FC<SideMenuProps> = ({ afterLogout }) => {
  const tryLogout = async (): Promise<void> => {
    // console.error(`[DEBUG] <SideMenu.tryLogout> Logging out...`);

    await menuController.close('main-menu');

    if(afterLogout !== undefined) {
      await afterLogout(
        {
          isLoggedIn: false,
          authToken: null,
          authUID: null,
          sessionID: null,
        },
      );
    }
  };

  return (
    <IonMenu
      menuId="main-menu"
      side="end"
      contentId="main"
      swipeGesture
      type="overlay"
    >
      <IonHeader>
        <IonToolbar
          color="primary"
          mode="ios"
        >
          <IonTitle id="main-title">
            {`Com999`}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonMenuToggle auto-hide="false">
            <IonItem
              lines="none"
              detail={false}
              routerLink="/rides"
            >
              <IonIcon
                ios={calendarOutline}
                md={calendarSharp}
              />
              <IonLabel>
                Rides
              </IonLabel>
            </IonItem>
          </IonMenuToggle>
          {/* <IonMenuToggle auto-hide="false">
            <IonItem
              lines="none"
              detail={false}
              routerLink="/geoloc"
            >
              <IonIcon
                ios={navigateOutline}
                md={navigateSharp}
              />
              <IonLabel>
                Geolocation
              </IonLabel>
            </IonItem>
          </IonMenuToggle> */}
          <IonMenuToggle auto-hide="false">
            <IonItem
              lines="none"
              detail={false}
              onClick={tryLogout}
            >
              <IonIcon
                ios={logOutOutline}
                md={logOutSharp}
              />
              <IonLabel>
                Log out
              </IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

/* Export SideMenu component --------------------------- */
export default SideMenu;
