/* Framework imports ----------------------------------- */
import React from 'react';

/* Module imports -------------------------------------- */

/* Component imports ----------------------------------- */
import {
  IonList,
  IonListHeader,
  IonImg,
  IonSpinner,
} from '@ionic/react';
import RideListItem from './RideListItem';

/* Style imports --------------------------------------- */

/* Type imports ---------------------------------------- */
import type { RideFragment } from '../../apollo/queries/types';

/* RideList component prop types ----------------------- */
interface RideListProps {
  rides: RideFragment[];
  title: string;
  loading?: boolean;
}

/* RideList component ---------------------------------- */
const RideList: React.FC<RideListProps> = (
  {
    rides,
    title,
    loading = false,
  },
) => {
  return (
    <IonList style={{ padding: '0 .5rem' }}>
      <IonListHeader style={{ padding: 0 }}>
        {title}
      </IonListHeader>
      {
        loading === true ?
          <div style={{ textAlign: 'center' }}>
            <IonSpinner />
            <h6>
              {`Loading rides...`}
            </h6>
          </div> :
          rides.length < 1 ?
            <div style={{ textAlign: 'center' }}>
              <h6>
                {`No rides to display for now`}
              </h6>
            </div> :
            rides.map(
              (pRide, pIndex) => {
                return (
                  <RideListItem
                    key={`ride-${pRide.id}-${pRide.displayName}-${pIndex}`}
                    ride={pRide}
                  />
                );
              },
            )
      }
    </IonList>
  );
};

/* Export RideList component --------------------------- */
export default RideList;
